import React, { createContext, useContext, useState } from 'react';

const SelectedCourseContext = createContext();

export function SelectedCourseProvider({ children }) {
  const [pathname, setPathname] = useState(window.location.pathname.substring(1));
  const [courseid, setCourseId] = useState(null);
  const [searchData, setSearchData] = useState(true);
  const [foundData, setFoundData] = useState(null);

  const updatePathname = (newPathname) => {
    setPathname(newPathname);
  };

  const updateSelectedCourseId = (newCourseId) => {
    setCourseId(newCourseId);
  };

  const updateSearchData = (newSearchData) => {
    setSearchData(newSearchData);
  };

  const updateFoundData = (newFoundData) => {
    setFoundData(newFoundData);
  };

  return (
    <SelectedCourseContext.Provider value={{ pathname, updatePathname, courseid, updateSelectedCourseId, foundData, updateFoundData, searchData, updateSearchData }}>
      {children}
    </SelectedCourseContext.Provider>
  );
}

export function useSelectedCourse() {
  return useContext(SelectedCourseContext);
}