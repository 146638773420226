import { get_kommun_from_code } from "./valuestore";


function generate_path_structure(paths, course) {

  let res = { 'overview': {},
    'akb': {}, 'regional': {}, 'occupations_regional': {}, 'occupations_direct': {}, 'skills': {}
  }

  // overview
  res['overview']['is_degree'] = course.info.resultIsDegree;
  res['overview']['form'] = course.info.form.code;
  res['overview']['configuration_code'] = course.info.configuration? course.info.configuration.code: '';
  res['overview']['title'] = course.info.title.string[0].content;
  res['overview']['credits'] = course.info.credits? course.info.credits.credits[0]: '';
  res['overview']['degree'] = course.info.degree && course.info.degree.length>0 ? course.info.degree[0].string[0].content: '';

  let paths_akb = paths.filter(x => x.edge.description == 'AKB')
  res['akb']['nr_paths'] = paths_akb.length;
  res['akb']['data'] = paths_akb;

  let paths_regional = paths.filter(x => x.edge.description == 'Regional')
  res['regional']['nr_paths'] = paths_regional.length;
  res['regional']['data'] = paths_regional;

  let paths_occs_regional = paths.filter(x => x.edge.description == 'Regional occupations')
  res['occupations_regional']['nr_paths'] = paths_occs_regional.length;
  res['occupations_regional']['data'] = paths_occs_regional;

  let paths_occs_direct = paths.filter(x => x.edge.description == 'Direct occupation')
  res['occupations_regional']['nr_paths'] = paths_occs_direct.length;
  res['occupations_regional']['data'] = paths_occs_direct;

  let paths_skills = paths.filter(x => x.edge.description == 'Skill')
  res['occupations_regional']['nr_paths'] = paths_skills.length;
  res['occupations_regional']['data'] = paths_skills;

  return res;
}

export function generate_path_cards(paths, courses) {

  let res = [];
  for(let i=0;i<paths.length;i++) {
    res.push(generate_path_structure(paths[i], courses[i]))
  }

  return res;
}


// Reorder output from forecast API to better fit the structure of the course card.
export function generate_forecast_cards(forecasts_Af = null, af_by_title = null, mappings = null, forecasts_lan = null, forecasts_riket = null, mappingsAKB = null, forecasts_AKBAntal = null, forecasts_AKBTillgang = null, yrken = null) {
  let forecast_cards = [];

  for(let i=0;i<mappings.length;i++) {

    let AKB = [];
    let regionala = [];

    let mapping = mappings[i].filter(x => x['utbgrupp'] != null);
    let forecast_lan = forecasts_lan[i].filter(x => x.length > 0);
    let forecast_riket = forecasts_riket[i].filter(x => x.length > 0);
    let mappingAKB = mappingsAKB[i].filter(x => x['utbgrupp'] != null);
    let forecast_AKBTillgang = forecasts_AKBTillgang[i].filter(x => x.length > 0);
    let forecast_AKBAntal = forecasts_AKBAntal[i].filter(x => x.length > 0);
    let y = yrken[i].filter(x => x.length > 0);

    // filter empty results
/*    AKB['mapping'] = AKB['mapping'].filter(x => x['utbgrupp'] != null)
    regionala['mapping'] = regionala['mapping'].filter(x => x['utbgrupp'] != null)

    regionala['Län'] = regionala['Län'].filter(x => x.length > 0)
    regionala['Riket'] = regionala['Riket'].filter(x => x.length > 0)
    regionala['Yrken'] = regionala['Yrken'].filter(x => x.length > 0)
*/

    AKB = mappingAKB.map((x,j)=> { return {'mapping': mappingAKB[j], 'Tillgång': forecast_AKBTillgang[j], 'Antal': forecast_AKBAntal[j]} })
    regionala = mapping.map((x,j)=> { return {'mapping': mapping[j], 'Län': forecast_lan[j], 'Riket': forecast_riket[j], 'Yrken': y[j] } })

    forecast_cards.push({'AKB': AKB, 'Regionala': regionala, 'Af_Regionala': forecasts_Af[i], 'Af_by_title': af_by_title[i] })
  }

  return forecast_cards;
}

export function generate_course_cards(content, page_info, mappings = null, forecasts_lan = null, forecasts_riket = null, mappingsAKB = null, forecasts_AKBAntal = null, forecasts_AKBTillgang = null, yrken = null) {
    
    let course_cards = [];

    if(content.length > 0){
      for(let i=0;i<content.length;i++) {
        const item = content[i];

        const course = item.info.title.string[0].content;
        const school = item.provider && item.provider.name.string ? item.provider.name.string[0].content : '-';
        
        let location = '-';
        if(item.event.location) {
          if('town' in item.event.location[0]) {
            location = item.event.location[0].town;
          }
          else if('municipalityCode' in item.event.location[0]) {
            location = get_kommun_from_code(item.event.location[0]['municipalityCode'])
          }
        }

        const type = item.info.form ? item.info.form.code[0].toUpperCase() + item.info.form.code.slice(1) : '-';
        
        let start = item.event.execution.start? item.event.execution.start: '-';
        start = start.length>10? start.substring(0,10): start;

        let end = item.event.execution.end? item.event.execution.end: '-';
        end =  end.length>10? end.substring(0,10): end;
        
        const form = item.event.distance? 'Distans': 'På plats';
        const pace = item.event.paceOfStudy ? item.event.paceOfStudy.percentage + '%' : '-';
        let last_signup_date = item.event.application? item.event.application.last: null; //item.event.extension && item.event.extension[0].applicationDetails && item.event.extension[0].applicationDetails[0].closeDate ?  item.event.extension[0].applicationDetails[0].closeDate[0] : '';

        if(last_signup_date && last_signup_date.length>10) {
          last_signup_date = last_signup_date.substring(0,10);
        }

        if(last_signup_date) { // exclude if before today
          const date_signup = Date.parse(last_signup_date);
          const today = new Date();
          if(date_signup<today) {
            last_signup_date = null;
          }
        }

        let points = item.info.credits ? item.info.credits.credits[0] : '-';
        points += item.info.credits ? ' ' + item.info.credits.system[0].code : '';

        const about = item.info.description? item.info.description.string[0].content: '-';

        const eligibility = item.info.eligibility && item.info.eligibility.eligibilityDescription ? item.info.eligibility.eligibilityDescription[0].string[0].content : '-';

        if(item.info.eligibility && !item.info.eligibility.eligibilityDescription){
          console.log('Warning: eligibility:' + item.info.eligibility)          
        }

        const name= school; //old responsible body: item.provider.responsibleBody ? item.provider.responsibleBody.name.string[0].content + ' (' + item.provider.responsibleBody.type.code + ')' : '';
        let website= item.event.url ? item.event.url.url[0].content : '-'; 
        website = website.match(/^https?:/) ? website : 'https://' + website; // add https if it is missing
        
        let address = '';
        if(item.provider && item.provider.visitAddress) {
          address = item.provider.visitAddress.streetAddress + ', ';
          if (item.provider.visitAddress.postCode) {
            address += item.provider.visitAddress.postCode + ' ';
          }

          address += item.provider.visitAddress.town;
        }

        const email= item.provider? item.provider.emailAddress: '-';
        const phone= item.provider && item.provider.phone ? item.provider.phone[0].number : '-';

        let c = { event_id: item.event.identifier, course: course, school: school, location: location, type: type, start: start, end: end, last_signup_date: last_signup_date, 
          form: form, pace: pace, points: points, about: about, eligibility: eligibility, name: name,
          website: website, address: address, email: email, phone: phone, is_degree: item.info.resultIsDegree,
        }

        course_cards.push(c);
      }
    }

    return course_cards;
  }