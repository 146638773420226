import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowTrendDown,
  faArrowTrendUp,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEquals,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";
import GaugeChart from "../components/GaugeChart/GaugeChart";

import { fetch_course_all } from "../utils/fetch_data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ForecastData = ({ courseId, goToStep }) => {
  const [courseCard, setCourseCard] = useState({ course: "Test" });

  const [data, setData] = useState({
    utbildningsgrupper: "",
    oneYearUp: "45",
    oneYearEqual: "54",
    oneYearDown: "1",
    threeYearUp: "55",
    threeYearEqual: "43",
    threeYearDown: "2",
    educatedUp: "16",
    educatedEqual: "52",
    educatedDown: "33",
    experienceUp: "0",
    experienceEqual: "19",
    experienceDown: "81",
    national: "up",
    regional: "down",
    occupation1: "",
    occupation1Value: "",
    occupation2: "",
    occupation2Value: "",
    occupation3: "",
    occupation3Value: "",
    occupation4: "",
    occupation4Value: "",
    trend_skills: "",
  });

  const handleData = (forecast_card, trend_skills) => {
    let utbildningsgrupper =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][0]["Utbildningsgrupptext"]
        : "";
    let oneYearUp =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][0]["Andel arbetsgivare som svarar öka"]
        : "";
    let oneYearEqual =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][0][
            "Andel arbetsgivare som svarar oförändrat"
          ]
        : "";
    let oneYearDown =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][0][
            "Andel arbetsgivare som svarar minska"
          ]
        : "";
    let threeYearUp =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][1]["Andel arbetsgivare som svarar öka"]
        : "";
    let threeYearEqual =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][1][
            "Andel arbetsgivare som svarar oförändrat"
          ]
        : "";
    let threeYearDown =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Antal"]
        ? forecast_card.AKB[0]["Antal"][1][
            "Andel arbetsgivare som svarar minska"
          ]
        : "";
    let educatedUp =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Tillgång"]
        ? forecast_card.AKB[0]["Tillgång"][0][
            "Andel arbetsgivare som svarar brist"
          ]
        : "";
    let educatedEqual =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Tillgång"]
        ? forecast_card.AKB[0]["Tillgång"][0][
            "Andel arbetsgivare som svarar balanserad tillgång"
          ]
        : "";
    let educatedDown =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Tillgång"]
        ? forecast_card.AKB[0]["Tillgång"][0][
            "Andel arbetsgivare som svarar god tillgång"
          ]
        : "";
    let experienceUp =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Tillgång"]
        ? forecast_card.AKB[0]["Tillgång"][1][
            "Andel arbetsgivare som svarar brist"
          ]
        : "";
    let experienceEqual =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Tillgång"]
        ? forecast_card.AKB[0]["Tillgång"][1][
            "Andel arbetsgivare som svarar balanserad tillgång"
          ]
        : "";
    let experienceDown =
      forecast_card.AKB.length > 0 && forecast_card.AKB[0]["Tillgång"]
        ? forecast_card.AKB[0]["Tillgång"][1][
            "Andel arbetsgivare som svarar god tillgång"
          ]
        : "";
    let national =
      forecast_card.Regionala.length > 0 && forecast_card.Regionala[0]["Riket"]
        ? forecast_card.Regionala[0]["Riket"][0]["Bedömning"]
        : "";
    let regional =
      forecast_card.Regionala.length > 0 && forecast_card.Regionala[0]["Län"]
        ? forecast_card.Regionala[0]["Län"][0]["Bedömning"]
        : "";

    let occupation1 =
      forecast_card.Regionala.length > 0 && forecast_card.Regionala[0]["Yrken"]
        ? forecast_card.Regionala[0]["Yrken"][0]["Yrke, text"]
        : "";
    let occupation1Value =
      forecast_card.Af_Regionala.length > 0 &&
      forecast_card.Af_Regionala[0].length > 0 &&
      forecast_card.Af_Regionala[0][0] &&
      forecast_card.Af_Regionala[0][0][1]
        ? forecast_card.Af_Regionala[0][0][1].efterfraga * 25
        : "";
    let occupation2 =
      forecast_card.Regionala.length > 0 && forecast_card.Regionala[0]["Yrken"]
        ? forecast_card.Regionala[0]["Yrken"][1]["Yrke, text"]
        : "";
    let occupation2Value =
      forecast_card.Af_Regionala.length > 0 &&
      forecast_card.Af_Regionala[0].length > 1 &&
      forecast_card.Af_Regionala[0][1] &&
      forecast_card.Af_Regionala[0][1][1]
        ? forecast_card.Af_Regionala[0][1][1].efterfraga * 25
        : "";
    let occupation3 =
      forecast_card.Regionala.length > 0 && forecast_card.Regionala[0]["Yrken"]
        ? forecast_card.Regionala[0]["Yrken"][2]["Yrke, text"]
        : "";
    let occupation3Value =
      forecast_card.Af_Regionala.length > 0 &&
      forecast_card.Af_Regionala[0].length > 2 &&
      forecast_card.Af_Regionala[0][2] &&
      forecast_card.Af_Regionala[0][2][1]
        ? forecast_card.Af_Regionala[0][2][1].efterfraga * 25
        : "";
    let occupation4 =
      forecast_card.Af_by_title.length > 0
        ? forecast_card.Af_by_title[0][0]
        : "";
    let occupation4Value =
      forecast_card.Af_by_title.length > 0 &&
      forecast_card.Af_by_title[0][1].length > 0
        ? forecast_card.Af_by_title[0][1][1].efterfraga * 25
        : "";

    return {
      utbildningsgrupper: utbildningsgrupper,
      oneYearUp: oneYearUp,
      oneYearEqual: oneYearEqual,
      oneYearDown: oneYearDown,
      threeYearUp: threeYearUp,
      threeYearEqual: threeYearEqual,
      threeYearDown: threeYearDown,
      educatedUp: educatedUp,
      educatedEqual: educatedEqual,
      educatedDown: educatedDown,
      experienceUp: experienceUp,
      experienceEqual: experienceEqual,
      experienceDown: experienceDown,
      national: national,
      regional: regional,
      occupation1: occupation1,
      occupation1Value: occupation1Value,
      occupation2: occupation2,
      occupation2Value: occupation2Value,
      occupation3: occupation3,
      occupation3Value: occupation3Value,
      occupation4: occupation4,
      occupation4Value,
      occupation4Value,
      trend_skills: trend_skills,
    };
  };

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      if (courseId !== undefined) {
        let { course, forecast, trend_skills, paths_skill } =
          await fetch_course_all(courseId);

        setData(handleData(forecast, trend_skills));
        setCourseCard(course);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const selectRef = createRef();

  const [kommuner, setKommuner] = useState([]);
  const [placeholderVar, setPlaceholderVar] = useState(null);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRef = useRef();

  const next = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const prev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const selectDropdownStyle = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? "#D3D3D4" : "#D3D3D4",
      "&:hover": {
        borderColor: state.isFocused ? "#D3D3D4" : "#D3D3D4",
      },
      backgroundColor: "transparent",
      borderRadius: 1000,
      fontSize: 16,
      paddingLeft: 16,
      fontWeight: 500,
      margin: 0,
      maxHeight: 42,
      color: "#22262F !important",
      border: "2px solid #D3D3D4",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? "transparent" : "transparent",
      };
    },
  };

  const backButton = (
    <div className="d-flex justify-content-center pt-5">
      <button
        type="button"
        onClick={() => goToStep(3)}
        className="btn-outline-tsl"
      >
        Prognos summering
      </button>
    </div>
  );

  return (
    <section>
      <Container>
        <Row>
          <div className="step-content">
            <Slider ref={sliderRef} {...sliderSettings}>
              {/* Slide 2 */}
              <div className="px-1">
                <div className="card-style">
                  {/* SCB’s Arbetskraftsbarometer */}
                  <h3 className="mb-4">
                    SCB:s Arbetskraftsbarometer del 1 av 2
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Statistiken visar arbetsmarknadsläget och utsikterna
                          på ett respektive tre års sikt för cirka 70
                          utbildningsgrupper, varav största delen är
                          högskoleutbildningar. Resultaten bygger på svaren på
                          en enkät till ett urval av arbetsgivare.
                          Undersökningen har genomförts varje höst sedan 1959.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h3>

                  <p className="mb-3">
                    {courseCard.course} tillhör SCB:s utbildningsgrupp:{" "}
                    <span className="preamble">{data.utbildningsgrupper}</span>
                  </p>

                  {/* Demand for individuals with Doctor’s Education */}
                  <h4 className="mb-3">
                    Arbetsgivarens syn gällande efterfrågan på personer inom
                    gruppen: {data.utbildningsgrupper}
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Arbetskraftsbarometern visar hur arbetsgivarna själva
                          har svarat på frågor om vilka utbildningar som har
                          brist på arbetssökande och inte. Det gör att
                          informationen om vilken kompetens som behövs på
                          arbetsmarknaden kommer direkt från de som står för
                          rekryteringen.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h4>

                  <div className="ps-4">
                    <p className="mb-3">
                      <strong>På 1 års sikt:</strong>
                    </p>

                    <div className="ps-4">
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-up">
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.oneYearUp}% av arbetsgivarna säger att
                          efterfrågan kommer att öka.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-equal">
                          <FontAwesomeIcon
                            icon={faEquals}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.oneYearEqual}% av arbetsgivarna säger att
                          efterfrågan kommer att förbli oförändrad.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-down">
                          <FontAwesomeIcon
                            icon={faArrowTrendDown}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.oneYearDown}% av arbsgivarna säger att
                          efterfrågan kommer att minska.
                        </p>
                      </div>
                    </div>

                    <p className="mb-3">
                      <strong>På 3 års sikt:</strong>
                    </p>

                    <div className="ps-4">
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-up">
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.threeYearUp}% av arbetsgivarna säger att
                          efterfrågan kommer att öka.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-equal">
                          <FontAwesomeIcon
                            icon={faEquals}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.threeYearEqual}% av arbetsgivarna säger att
                          efterfrågan kommer att förbli oförändrad.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3">
                        <div className="icon-circle-down">
                          <FontAwesomeIcon
                            icon={faArrowTrendDown}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.threeYearDown}% av arbsgivarna säger att
                          efterfrågan kommer att minska.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Slide 3 */}
              <div className="px-1">
                <div className="card-style">
                  {/* Supply of individuals with Doctor's education */}
                  <h3 className="mb-4">
                    SCB:s Arbetskraftsbarometer del 2 av 2
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Statistiken visar arbetsmarknadsläget och utsikterna
                          på ett respektive tre års sikt för cirka 70
                          utbildningsgrupper, varav största delen är
                          högskoleutbildningar. Resultaten bygger på svaren på
                          en enkät till ett urval av arbetsgivare.
                          Undersökningen har genomförts varje höst sedan 1959.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h3>

                  <p className="mb-3">
                    {courseCard.course} tillhör SCB:s utbildningsgrupp:{" "}
                    <span className="preamble">{data.utbildningsgrupper}</span>
                  </p>
                  <h4 className="mb-3">
                    Arbetsgivarens syn gällande tillgång på personer inom
                    gruppen: {data.utbildningsgrupper}
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin nec scelerisque arcu, non tempor enim.
                          Phasellus nec leo sem.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h4>

                  <div className="ps-4">
                    <p className="mb-3">
                      <strong>Nyligen utbildade personer</strong>
                    </p>

                    <div className="ps-4">
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-up">
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.educatedUp}% av arbetsgivarna säger att det
                          råder brist på tillgång.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-equal">
                          <FontAwesomeIcon
                            icon={faEquals}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.educatedEqual}% av arbetsgivarna säger att
                          tillgången är i balans.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-down">
                          <FontAwesomeIcon
                            icon={faArrowTrendDown}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.educatedDown}% av arbetsgivarna säger att det
                          finns en god tillgång.
                        </p>
                      </div>
                    </div>

                    <p className="mb-3">
                      <strong>Personer med arbetslivserfarenhet</strong>
                    </p>

                    <div className="ps-4">
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-up">
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.experienceUp}% av arbetsgivarna säger att det
                          råder brist på tillgång.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-equal">
                          <FontAwesomeIcon
                            icon={faEquals}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.experienceEqual}% av arbetsgivarna säger att
                          tillgången är i balans.
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-down">
                          <FontAwesomeIcon
                            icon={faArrowTrendDown}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>
                          {data.experienceDown}% av arbetsgivarna säger att det
                          finns en god tillgång.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Slide 4 */}
              <div className="px-1">
                <div className="card-style">
                  {/* SCB’s Regional & National Forecast 2035 */}
                  <h3 className="mb-2">
                    SCB:s trender och prognoser: 2035
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          SCB presenterar långsiktiga prognoser över tillgång
                          och efterfrågan på arbetskraft för olika
                          utbildningsgrupper. Detta görs bland annat genom att
                          studera strömmarna genom utbildningssystemet och ut på
                          arbetsmarknaden samt utbildningskravens förändringar
                          på olika delar av arbetsmarknaden.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h3>

                  <p className="mb-4">
                    {courseCard.course} tillhör SCB:s utbildningsgrupp:{" "}
                    <span className="preamble">{data.utbildningsgrupper}</span>
                    <br />
                    SCB:s 2035 prognos för gruppen{" "}
                    <span className="preamble">
                      {data.utbildningsgrupper}
                    </span>{" "}
                    är följande:
                  </p>

                  {/* National Forecast */}
                  <h4 className="mb-3">
                    Prognos för hela Sverige
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          SCB har utvecklat två prognosområden: riket och län. I
                          vissa fall är en prognos tillgänglig för båda dessa
                          områden, medan den i vissa fall endast gäller för ett
                          av dem. Ibland kan det också saknas en prognos.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h4>

                  <div className="ps-4 mb-4">
                    {data.national &&
                    data.national.toLowerCase().includes("brist") ? (
                      <div className="d-flex align-items-center gap-3">
                        <div className="icon-circle-up">
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>{data.national}</p>
                      </div>
                    ) : data.national &&
                      (data.national.toLowerCase().includes("balans") ||
                        data.national.toLowerCase().includes("varierat")) ? (
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-equal">
                          <FontAwesomeIcon
                            icon={faEquals}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>{data.national}</p>
                      </div>
                    ) : data.national &&
                      (data.national.toLowerCase().includes("överskott") ||
                        data.national.toLowerCase().includes("nedåt")) ? (
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="icon-circle-down">
                          <FontAwesomeIcon
                            icon={faArrowTrendDown}
                            style={{ color: "#ffffff" }}
                          />
                        </div>

                        <p>{data.national}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* Regional */}
                  <h4 className="mb-3">
                    Välj ett län du vill se en prognos för:
                  </h4>

                  <Form className="mb-3">
                    <Form.Group
                      controlId="formRegion"
                      className="regional-input"
                    >
                      <Select
                        //value={region}
                        className="advanced-form-select"
                        defaultValue={kommuner[0]}
                        styles={selectDropdownStyle}
                        options={kommuner}
                        onChange={(selectedOption) =>
                          setPlaceholderVar(selectedOption)
                        }
                        ref={selectRef}
                      />
                    </Form.Group>
                  </Form>

                  {placeholderVar !== null && (
                    <div className="ps-4">
                      {data.regional.includes("brist") ? (
                        <div className="d-flex align-items-center gap-3">
                          <div className="icon-circle-up">
                            <FontAwesomeIcon
                              icon={faArrowTrendUp}
                              style={{ color: "#ffffff" }}
                            />
                          </div>

                          <p>{data.regional}</p>
                        </div>
                      ) : data.regional.includes("balans") ? (
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <div className="icon-circle-equal">
                            <FontAwesomeIcon
                              icon={faEquals}
                              style={{ color: "#ffffff" }}
                            />
                          </div>

                          <p>{data.regional}</p>
                        </div>
                      ) : data.regional.includes("överskott") ||
                        data.regional.includes("nedåt") ? (
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <div className="icon-circle-down">
                            <FontAwesomeIcon
                              icon={faArrowTrendDown}
                              style={{ color: "#ffffff" }}
                            />
                          </div>

                          <p>{data.regional}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Slide 5 */}
              <div className="px-1">
                <div className="card-style">
                  {/* Arbetsförmedlingens Forecast */}
                  <h3 className="mb-4">
                    Arbetsförmedlingens Prognos för yrken i relation till:{" "}
                    {courseCard.course}
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Det finns möjligen flera jobb än de som visas här som
                          är relaterade till den utbildning du tittar på. Syftet
                          med att visa några av yrkena som har en koppling till
                          utbildningen är att ge dig en uppfattning om
                          efterfrågan och möjligheter på arbetsmarknaden.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </h3>

                  <Row>
                    <Col
                      lg={6}
                      className="d-flex flex-column align-items-center mb-5"
                    >
                      <h4 className="mb-3">{data.occupation1}</h4>

                      <GaugeChart value={data.occupation1Value} />
                    </Col>

                    <Col
                      lg={6}
                      className="d-flex flex-column align-items-center mb-5"
                    >
                      <h4 className="mb-3">{data.occupation2}</h4>

                      <GaugeChart value={data.occupation2Value} />
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      lg={6}
                      className="d-flex flex-column align-items-center mb-5 mb-lg-0"
                    >
                      <h4 className="mb-3">{data.occupation3}</h4>

                      <GaugeChart value={data.occupation3Value} />
                    </Col>

                    {/*
                      <Col lg={6} className="d-flex flex-column align-items-center mb-5 mb-lg-0">
                          <h4 className="mb-3">{data.occupation4}</h4>

                          <GaugeChart value={data.occupation4Value} />
                      </Col>
                    */}
                  </Row>
                </div>
              </div>

              {/* Slide 6 */}
              {data.trend_skills.length > 0 &&
                data.trend_skills[0].length > 0 && (
                  <div className="px-1">
                    <div className="card-style">
                      <div className="info-div">
                        <h3 className="mb-2">
                          Trend för kompetens: {data.trend_skills[0]["name"]}.
                        </h3>

                        <p className="mb-3">
                          Efterfrågan i andel annonser 2022:{" "}
                          <strong>
                            {Math.round(
                              data.trend_skills[0]["freq_2022"] * 100 * 10
                            ) / 10}
                            %
                          </strong>
                        </p>

                        <p className="mb-3">
                          Nuvarande tillväxt i efterfrågan per år:{" "}
                          <strong>
                            {Math.round(data.trend_skills[0]["growth"] * 10) /
                              10}
                            %
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </Slider>

            <div className="slider-nav">
              <button onClick={prev}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              <button onClick={next}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>

            {backButton}
          </div>
        </Row>
      </Container>
    </section>
  );
};
