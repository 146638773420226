import { get_params, ug_get_params } from './filters.js';

const api_url_susa = 'https://susanavet2.skolverket.se/api/1.1/';
const api_url_ug = 'https://api.dinjobbframtid.se/susa-ug-search/';//'https://api.skolverket.se/planned-educations/v3/adult-education-events'

// map and combine incoming data into one array
function combine_data(js_events, js_infos, js_providers) {

    const id2provider = Object.fromEntries(js_providers.content.map(x => [x.content.educationProvider.identifier, x.content.educationProvider]));
    const id2info = Object.fromEntries(js_infos.content.map(x => [x.content.educationInfo.identifier, x.content.educationInfo]));

    let res = []
    for (let i = 0; i < js_events.content.length; i++) {

        const event = js_events.content[i].content.educationEvent;
        const info = id2info[js_events.content[i].content.educationEvent.education];

        if (js_events.content[i].content.educationEvent.provider.length > 1) {
            console.log('WARNNG: provider.length>1')
        }

        const provider = id2provider[js_events.content[i].content.educationEvent.provider[0]];

        let c = { 'event': event, 'info': info, 'provider': provider }

        res.push(c)
    }

    return res;
}

// fetch data from only susanavet api:s
export async function susanavet_fetch(page = 0, search_string, filter_start, filter_kommun, filter_studieform, filter_studieamne, filter_studietakt, filter_sprak, filter_anordnare) {

    const params = get_params(search_string, filter_start, filter_kommun, 
                    filter_studieform, filter_studieamne, filter_studietakt, filter_anordnare, filter_sprak);

    let url_events = api_url_susa + 'events';

    url_events += '?page=' + page;

    if (params !== '') {
        const params_str = '&' + params.join('&');
        url_events += params_str;
    }

    // events
    const js_events = await (await fetch(url_events)).json();
    let event_ids = js_events.content.map((item, i) => item.content.educationEvent.education)

    // infos from events
    const url_infos = api_url_susa + 'infos?id=' + event_ids.join(',');
    const js_infos = await (await fetch(url_infos)).json();

    // providers
    let event_providers = js_events.content.map((item, i) => item.content.educationEvent.provider[0])
    event_providers = [...new Set(event_providers)];

    const url_providers = api_url_susa + 'providers?id=' + event_providers.join(',');
    const js_providers = await (await fetch(url_providers)).json();

    const data = combine_data(js_events, js_infos, js_providers);

    return [data, js_events.page];
}


// fetch data from api:s from susanavet + utbildningsguiden
export async function fetch_courses(search_string, page = 0, filter_start = '', filter_kommun = '', filter_studieform = '', filter_studieamne = '', filter_studietakt = '', filter_sprak = '', filter_anordnare = '') {

    const params = ug_get_params(search_string, filter_start, filter_kommun, 
                    filter_studieform, filter_studieamne, filter_studietakt, filter_anordnare, filter_sprak);

    let url_events = api_url_ug;

    url_events += '?page=' + page + '&sort=relevance:desc';//'&sort=title%2Cdesc';//,relevance%2Cdesc';

    if (params.length>0) {
        const params_str = '&' + params.join('&');
        url_events += params_str;
    }

    const ug_events = await (await fetch(url_events, {
        headers: {
            "accept": "application/vnd.skolverket.plannededucations.api.v3.hal+json",
        }
    } ) ).json();

    // 1. events from ug search
    const summary = ug_events.body.page;

    if(!summary || summary.totalElements === 0)
        return [[], summary]

    let event_ids = ug_events.body._embedded.listedAdultEducationEvents.map((item, i) => item.educationEventId)

    // 2. corresponding susa events
    const url_events_susa = api_url_susa + 'events?id=' + event_ids.join(',');
    const js_events = await (await fetch(url_events_susa)).json();

    // resort according to ug search order
    js_events.content.sort((a,b) => event_ids.indexOf(a.content.educationEvent.identifier) - event_ids.indexOf(b.content.educationEvent.identifier))

    // 3. infos from events
    const url_infos = api_url_susa + 'infos?id=' + event_ids.join(',');
    const js_infos = await (await fetch(url_infos)).json();

    // 4. providers
    let event_providers = js_events.content.map((item, i) => item.content.educationEvent.provider[0])
    event_providers = [...new Set(event_providers)];

    const url_providers = api_url_susa + 'providers?id=' + event_providers.join(',');
    const js_providers = await (await fetch(url_providers)).json();

    const data = combine_data(js_events, js_infos, js_providers);

    return [data, summary]
}