import { createContext, useContext, useReducer } from "react";

import { fetch_courses } from "../utils/search.js";
import { generate_course_cards } from "../utils/generate_cards.js";

const EducationContext = createContext();

const initial_filters = {
  filter_start: "",
  filter_kommun: { label: "Alla", value: "" },
  filter_studieform: { label: "Alla", value: "" },
  filter_studieamne: { label: "Alla", value: "" },
  filter_studietakt: { label: "Alla", value: "" },
  filter_sprak: { label: "Alla", value: "" },
  filter_anordnare: { label: "Alla", value: "" },
};

const initialState = {
  search_string: "",
  page: 0,
  filters: initial_filters,
  pageDetails: { page: 0, currentElements: 0, totalElements: 0 },
  isloading: false,
  course_cards: [],
};

async function gen_course_cards(filters, search_string, page) {
  // fetch courses from search string
  const {
    filter_start,
    filter_kommun,
    filter_studieform,
    filter_studieamne,
    filter_studietakt,
    filter_sprak,
    filter_anordnare,
  } = filters;
  const [courses, page_info] = await fetch_courses(
    search_string,
    page,
    filter_start,
    filter_kommun.value,
    filter_studieform.value,
    filter_studieamne.value,
    filter_studietakt.value,
    filter_sprak.value,
    filter_anordnare.value
  );

  // generate course card data
  const new_course_cards = generate_course_cards(courses, page_info);

  return new_course_cards;
}

function reducer(state, action) {
  switch (action.type) {
    case "search_string":
      return {
        ...state,
        search_string: action.search_string,
      };
    case "set_filters":
      return {
        ...state,
        filters: action.filters,
      };
    case "clear_result":
      return {
        ...state,
        course_cards: [],
      };
    case "page":
      return {
        ...state,
        pageDetails: action.page,
      };
    case "loading":
      return {
        ...state,
        isLoading: true,
      };
    case "search":
      return {
        ...state,
        isLoading: true,
        course_cards: [...state.course_cards, ...action.course_cards],
        isLoading: false,
      };
    default:
      throw new Error("Unknown action type");
  }
}

function EducationProvider({ children }) {
  const [
    { isLoading, search_string, filters, pageDetails, courses, course_cards },
    dispatch,
  ] = useReducer(reducer, initialState);

  function setSearchString(search_string) {
    dispatch({ type: "search_string", search_string: search_string });
  }

  function setFilters(filters) {
    dispatch({ type: "set_filters", filters: filters });
  }

  function setPageDetails(pageDetails) {
    dispatch({ type: "page", page: pageDetails });
  }

  function clearResult() {
    dispatch({ type: "clear_result" });
  }

  async function clearFilters() {
    await dispatch({ type: "clear_filters" });
  }

  // append: if true, append result to list
  async function doSearch() {
    await dispatch({ type: "loading" });

    try {
      // fetch courses from search string
      const {
        filter_start,
        filter_kommun,
        filter_studieform,
        filter_studieamne,
        filter_studietakt,
        filter_sprak,
        filter_anordnare,
      } = filters;
      const { page } = pageDetails;
      const [courses, page_info] = await fetch_courses(
        search_string,
        page,
        filter_start,
        filter_kommun.value,
        filter_studieform.value,
        filter_studieamne.value,
        filter_studietakt.value,
        filter_sprak.value,
        filter_anordnare.value
      );

      // generate course card data
      const new_course_cards = generate_course_cards(courses, page_info);

      // page details
      let pd = pageDetails;
      pd.totalElements = page_info.totalElements;
      pd.currentElements = (pageDetails.page + 1) * 20;
      dispatch({ type: "page", page: pd });

      dispatch({ type: "search", course_cards: new_course_cards });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error loading the data...",
      });
    }
  }

  return (
    <EducationContext.Provider
      value={{
        isLoading,
        search_string,
        filters,
        pageDetails,
        course_cards,
        doSearch,
        clearResult,
        clearFilters,
        setSearchString,
        setFilters,
        setPageDetails,
      }}
    >
      {children}
    </EducationContext.Provider>
  );
}

function useEducation() {
  const context = useContext(EducationContext);
  if (context === undefined)
    throw new Error("EducationContext was used outside the EducationProvider");
  return context;
}

export { EducationProvider, useEducation };
