import { generate_path } from "../utils/paths.js";
import { get_code_from_utb } from '../utils/codes.js';

import { _all_rapporter } from "../utils/data.js";

const api_forecast = 'https://api-prognos-utb.dinjobbframtid.se/';
const file_path = 'C:/Users/Simon/Documents/Projects/prognos_utbildning/library/version1/src/data/';


//let _all_rapporter = []


export async function initialize_MYH() {

/*    let filename = file_path + 'MYHRapporter.json';
    let rawdata = (await fs).readFileSync(filename)
    _all_rapporter = JSON.parse(rawdata);*/
    //_sun2rapport = Object.assign({}, ..._all_rapporter.map((x) => ({[x.SUN.toString()]: x})));
}


/*export async function fetch_MYH_from_API() {

    let url = api_forecast + 'MYHRapporter';
    _all_rapporter = await (await fetch(url)).json();
    //_sun2rapport = Object.assign({}, ..._all_rapporter.map((x) => ({[x.SUN.toString()]: x})));
}*/

function get_forecast_single(course) {

    let res = [];
    let codes = get_code_from_utb(course)
    codes = codes.filter(x=> x.type == 'sun')

    for(let i=0;i<codes.length;i++) {
        const sun_code = codes[i].code;

        res.push(..._all_rapporter.filter(x=>x.SUN.includes(sun_code)))
    }

    return res
}

export function get_forecasts_MYH(courses) {

    let res_MYH = [];

    for(let i=0;i<courses.length;i++) {
        res_MYH.push(...get_forecast_single(courses[i]))
    }

    return { myh: res_MYH };
}