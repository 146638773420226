import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./CourseCard.css";

import { DarkModeContext } from "../../App";
import { useSelectedCourse } from "../../contexts/SelectedCourseContext";

export default function CourseCard(props) {
  const { updatePathname, updateSearchData, updateFoundData } =
    useSelectedCourse();
  const isMobileDevice = /Mobi/i.test(navigator.userAgent);

  const [openCard, setOpenCard] = useState(0);

  const handleButtonClick = () => {
    updatePathname(props.course_id);
    updateSearchData(true);
    updateFoundData(false);
    window.history.pushState(null, "", "/" + props.course_id);
    props.goToStep(3);
  };

  return (
    <DarkModeContext.Consumer>
      {(darkMode) => (
        <div className="card-style">
          <Row className="info-div justify-content-between">
            <Col fluid="true" className="mb-3 mb-xxl-0">
              <h2 className="mb-3">{props.course}</h2>

              {props.last_signup_date ? (
                <div className="d-flex align-items-center gap-2">
                  <div className="status-on"></div>
                  <span>Öppen för anmälan</span>
                  <br />
                </div>
              ) : (
                ""
              )}

              {props.last_signup_date ? (
                <div className="d-flex align-items-center gap-2">
                  <div className="status-on"></div>
                  <span>Sista anmälningsdag: {props.last_signup_date}</span>
                </div>
              ) : (
                ""
              )}

              {isMobileDevice && (
                <div className="d-flex align-self-end gap-3 mb-4 pb-2">
                  <button
                    type="submit"
                    className="btn-outline-tsl"
                    onClick={() => window.open(props.website, "_blank")}
                  >
                    Till anordnarens webbplats
                  </button>
                </div>
              )}
            </Col>

            <Col
              className="d-flex flex-column justify-content-between"
              xxl="auto"
            >
              {!isMobileDevice && (
                <div className="d-flex align-self-end gap-3 mb-3">
                  <button
                    type="submit"
                    className="btn-outline-tsl"
                    onClick={() => window.open(props.website, "_blank")}
                  >
                    Till anordnarens webbplats
                  </button>
                </div>
              )}

              <button
                type="button"
                onClick={() => setOpenCard(!openCard)}
                aria-controls="collapse-info"
                aria-expanded={openCard}
                className="link-tsl w-auto ms-auto"
              >
                Visa {openCard ? "mindre" : "mer"}{" "}
                {openCard ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </button>
            </Col>
          </Row>

          <Row className="info-div">
            <Col lg={6} className="mb-4 mb-lg-0">
              <div className="mb-4">
                <h4>Anordnare</h4>
                <p>{props.school}</p>
              </div>

              <div className="mb-4">
                <h4>Studieplats</h4>
                <p>{props.location}</p>
              </div>

              <div className="mb-4">
                <h4>Poäng</h4>
                <p>{props.points}</p>
              </div>

              <div className="mb-4">
                <h4>Studiestart</h4>
                <p>{props.start}</p>
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-4">
                <h4>Undervisningsnivå</h4>
                <p>{props.type}</p>
              </div>

              <div className="mb-4">
                <h4>Studietakt</h4>
                <p>{props.pace}</p>
              </div>

              <div className="mb-4">
                <h4>Utbildningsform</h4>
                <p>{props.form}</p>
              </div>

              <div>
                <h4>Studieslut</h4>
                <p>{props.end}</p>
              </div>
            </Col>
          </Row>

          <button
            type="button"
            onClick={() => setOpenCard(!openCard)}
            aria-controls="collapse-info"
            aria-expanded={openCard}
            className="link-tsl w-auto ms-auto d-lg-none"
          >
            Visa {openCard ? "mindre" : "mer"}{" "}
            {openCard ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </button>

          <Row>
            <Collapse in={openCard}>
              <div id="collapse-info">
                <div className="info-div">
                  <h3 className="mb-3">Mer information om utbildningen</h3>
                  <div dangerouslySetInnerHTML={{ __html: props.about }} />
                  {/*<p>{props.about}</p>*/}
                </div>

                <div className="info-div">
                  <h3 className="mb-3">Förkunskapskrav</h3>
                  <p>{props.eligibility}</p>
                </div>

                <div>
                  <h3 className="mb-3">Kontakt</h3>
                  <Row>
                    {props.name ? (
                      <Col lg={6} className="mb-4">
                        <h4>Namn</h4>
                        <p>{props.name}</p>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {props.website ? (
                      <Col lg={6} className="mb-4">
                        <h4>Webbplats</h4>
                        <p>
                          <a href={props.website} target="_blank">
                            Till utbildningens webbplats
                          </a>
                        </p>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {props.address ? (
                      <Col lg={6} className="mb-4">
                        <h4>Address</h4>
                        <p>{props.address}</p>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {props.email ? (
                      <Col lg={6} className="mb-4">
                        <h4>E-Post</h4>
                        <p>{props.email}</p>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {props.phone ? (
                      <Col lg={6} className="mb-4">
                        <h4>Telefon</h4>
                        <p>{props.phone}</p>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>

                <Row>
                  <button
                    type="button"
                    onClick={() => setOpenCard(!openCard)}
                    aria-controls="collapse-info"
                    aria-expanded={openCard}
                    className="link-tsl w-auto ms-auto"
                  >
                    Visa {openCard ? "mindre" : "mer"}{" "}
                    {openCard ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </button>
                </Row>
              </div>
            </Collapse>
          </Row>

          <Row>
            <div className={`d-flex justify-content-center mt-4`}>
              <button
                type="button"
                onClick={handleButtonClick}
                className="btn-outline-tsl btn-forecast"
              >
                Välja utbildningen och generera en prognos
              </button>
            </div>
          </Row>
        </div>
      )}
    </DarkModeContext.Consumer>
  );
}
