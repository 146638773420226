const _url_gen_api = 'https://gen-yttrande-api.inteltjanster.workers.dev/';//'http://127.0.0.1:8787/';//'https://gen-yttrande-api.inteltjanster.workers.dev'; // 'http://127.0.0.1:8787';

let user_answers = { 'is_working_in_area': '', 'occupation': 'vvs-ingenjör', 
'has_other_related_competencies': '', 
'necessary_for_occupation_change': '', 
'why_necessary': 'behöver för att få examen' }

export async function set_answers(answers) {
    user_answers.is_working_in_area = answers.answer1 === 'true'? true: false;
    user_answers.occupation = answers.answer2;
    user_answers.has_other_related_competencies = answers.answer3 === 'true'? true: false;
    user_answers.necessary_for_occupation_change = answers.answer4 === 'true'? true: false;
    user_answers.why_necessary = answers.answer5;
}

export function get_answers(answers) {
    return user_answers;
}

export async function generate_assessment(course_card, forecast_card, trend_skills, endpoint = 'summary', user_answers = {}) {

    user_answers = { 'is_working_in_area': false, 'occupation': 'vvs-ingenjör', 'has_other_related_competencies': false, 'necessary_for_occupation_change': false, 'why_necessary': 'behöver för att få examen'}

    const data = JSON.stringify({ 'course_card': course_card, 'forecast_card': forecast_card, 'user_answers': user_answers, 'trend_skills': trend_skills });
    const init = {
        body: data,
        method: "POST",
        headers: {
            "content-type": "text/plain;charset=UTF-8",
        },
    };

    const response = await fetch(_url_gen_api + endpoint, init);
    const js = await response.json()

    return js;
}