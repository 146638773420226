import React from "react";
import Search from "../../components/Search/Search";
import { Col, Container, Row, Button } from "react-bootstrap";
import Filters from "../../components/Filters/Filters";
import CourseCard from "../../components/CourseCard/CourseCard";

import "./Education-catalog.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEducation } from "../../contexts/EducationContext";

let isSearched = false;

let noResults = false;
let isMoreThan20 = false;

let showMoreButton = false;
let elementsPerPage = 20;

export function EducationCatalog(props) {
  const {
    isLoading,
    search_string,
    filters,
    pageDetails,
    course_cards,
    doSearch,
    clearResult,
    clearFilters,
    setSearchString,
    setFilters,
    setPageDetails,
  } = useEducation();

  async function search() {
    await doSearch();
    isSearched = true;

    // logic for setting buttons and links
    if (pageDetails.totalElements === 0) noResults = true;
    else if (pageDetails.totalElements > elementsPerPage) {
      noResults = false;
      isMoreThan20 = true;
      showMoreButton = true;
    } else {
      isMoreThan20 = false;
      noResults = false;
    }
  }

  // Called when dropdown start time changed
  async function handleChangeStart(event) {
    let f = filters;
    f["filter_start"] = event;
    setFilters(f);
    clearResult();
    await search();
  }

  // Called when button reset filters clicked
  async function resetFilters(event) {
    event.preventDefault();

    const filters_cleared = {
      filter_start: "",
      filter_kommun: { label: "Alla", value: "" },
      filter_studieform: { label: "Alla", value: "" },
      filter_studieamne: { label: "Alla", value: "" },
      filter_studietakt: { label: "Alla", value: "" },
      filter_sprak: { label: "Alla", value: "" },
      filter_anordnare: { label: "Alla", value: "" },
    };
    const keys = Object.keys(filters_cleared);

    let f = filters;
    for (let i = 0; i < keys.length; i++) f[keys[i]] = filters_cleared[keys[i]];

    setFilters(f);

    clearResult();
    await search();
  }

  // Called when button search clicked / enter pressed
  async function handleSubmit(event) {
    event.preventDefault();

    clearResult();
    await search();
  }

  // Called when 'next page' link (bottom) clicked (shown if >20 results)
  async function fetchNextPage() {
    let pd = pageDetails;
    pd.page += 1;
    setPageDetails(pd);

    // search and append result
    await search();
  }

  // Called when a filter is changed
  async function handleChangeFilter(event, filter_name) {
    let f = filters;
    f[filter_name] = event;

    setFilters(f);
    clearResult();
    await search();
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        transition={Slide}
        hideProgressBar
        theme="dark"
      />

      <section>
        <Container>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              className="text-center d-flex flex-column gap-4"
            >
              <h1>Hitta din utbildning</h1>
              <p className="preamble">
                Första steget är att du söker fram den utbildning som är
                intresserad av. I fältet nedan skriver du in utbildningens namn
                ett sökord. Vill du kan du filtrera din sökning som till exempel
                studietakt och ort där du vill studera. Tryck sedan på knappen
                ”sök”. Du får då en lista på utbildningar att välja mellan. När
                du hittat en utbildning du är intresserad av klickar du på 'välj
                utbildning och generera prognos'.
              </p>

              <Search
                start={props.filter_start}
                search_string={search_string}
                handleChange={(e) => setSearchString(e.target.value)}
                handleSubmit={handleSubmit}
                handleChangeStart={handleChangeStart}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {!isLoading && isSearched ? (
        <section id="searchResult">
          <Container>
            <Row className="gap-lg-4 gap-md-3 gap-5">
              <Col md={4} lg={3}>
                <Filters
                  totalElements={pageDetails.totalElements}
                  region={filters.filter_kommun}
                  studietakt={filters.filter_studietakt}
                  studieform={filters.filter_studieform}
                  amne={filters.filter_amne}
                  anordnare={filters.filter_anordnare}
                  sprak={filters.filter_sprak}
                  handleChangeFilter={handleChangeFilter}
                  resetFilters={resetFilters}
                />
              </Col>

              <Col className="courses">
                {course_cards.map((c, i) => (
                  <CourseCard
                    key={i}
                    course_card={c}
                    forecast_card={null}
                    trend_skills={null}
                    course={c.course}
                    school={c.school}
                    location={c.location}
                    type={c.type}
                    start={c.start}
                    end={c.end}
                    last_signup_date={c.last_signup_date}
                    form={c.form}
                    pace={c.pace}
                    points={c.points}
                    about={c.about}
                    eligibility={c.eligibility}
                    pre1={c.pre1}
                    pre2={c.pre2}
                    pre3={c.pre3}
                    pre4={c.pre4}
                    name={c.name}
                    website={c.website}
                    address={c.address}
                    email={c.email}
                    phone={c.phone}
                    course_id={c.event_id}
                    goToStep={props.goToStep}
                  />
                ))}

                {noResults ? (
                  <span className="text-center">
                    Inga resultat hittades för denna sökning
                  </span>
                ) : (
                  <></>
                )}

                {isMoreThan20 && !noResults ? (
                  <div className="load-more">
                    <span>
                      {pageDetails.currentElements} av{" "}
                      {pageDetails.totalElements} träffar
                    </span>

                    {showMoreButton ? (
                      <button
                        type="button"
                        className="link-tsl mx-auto"
                        onClick={fetchNextPage}
                      >
                        Visa nästa {elementsPerPage} sökträffar
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
