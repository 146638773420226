import { generate_path } from "../utils/paths.js";
//const fs = import('fs');
//import _all_yrkesgrupper from '../data/AfYrkesgrupp.json'  assert { type: 'json' };

import { _all_yrkesgrupper } from "../utils/data.js";

const api_forecast = 'https://api-prognos-utb.dinjobbframtid.se/';
//const file_path = 'C:/Users/Simon/Documents/Projects/prognos_utbildning/library/version1/src/data/';

//let _all_yrkesgrupper = []
let _all_yrkesomraden = []

let _ssyk2yrkesprognoser = []
let _name_yrkesprognoser = []

function change_name_yrkesgrupper(name) {
    
    let n = name;
    if(name.substring(name.length-2, name.length) == 'er')
        n = name.substring(0, name.length-2);

    return n;
}

export async function initialize_Af() {

    // mapping
    //let filename = file_path + 'AfYrkesgrupp.json';
    //let rawdata = (await fs).readFileSync(filename)
    //_all_yrkesgrupper = JSON.parse(rawdata);

    //_all_yrkesgrupper = await (await fetch('./susa_forecast/AfYrkesgrupp.json')).json()
    
    //_all_yrkesgrupper = await import("../data/AfYrkesgrupp.json", {
    //    assert: { type: "json" },
    //  });
    //_all_yrkesgrupper = await (await fetch(filename)).json();

    // (!) replacement of non-json characters should be fixed upstream (dataframe to json export)
    _name_yrkesprognoser = _all_yrkesgrupper.map((x) => [change_name_yrkesgrupper(x.name.toString()),
        JSON.parse(x.yrkesprognoser.replaceAll(': NULL',': null').replaceAll(': None', ': null').replaceAll("'",'"')), x]);

    _ssyk2yrkesprognoser = Object.assign({}, ..._all_yrkesgrupper.map((x) => ({[x.ssyk.toString()]: JSON.parse(x.yrkesprognoser.replaceAll(': NULL',': null').replaceAll(': None', ': null').replaceAll("'",'"'))})));   
}


/*export async function fetch_Af_from_API() {

    // mapping
    let url = api_forecast + 'AfYrkesgrupp';
    _all_yrkesgrupper = await (await fetch(url)).json();

    // change to deep copy
    //_all_yrkesgrupper_modified = _all_yrkesgrupper.map( x => { return change_name_yrkesgrupper(x)});
    _name_yrkesprognoser = _all_yrkesgrupper.map((x) => [change_name_yrkesgrupper(x.name.toString()),
        JSON.parse(x.yrkesprognoser.replaceAll(': NULL',': null').replaceAll(': None', ': null')), x]);

    _ssyk2yrkesprognoser = Object.assign({}, ..._all_yrkesgrupper.map((x) => ({[x.ssyk.toString()]: JSON.parse(x.yrkesprognoser.replaceAll(': NULL',': null').replaceAll(': None', ': null'))})));   
}*/

export function match_courses_by_title(courses) {

    let res = [];
    let paths = [];

    for(let i=0;i<courses.length;i++) {
        let title = courses[i].info.title.string[0].content;
        
        let matches = _name_yrkesprognoser.filter(x => title.toLowerCase().includes(x[0].toLowerCase()));
        res.push(matches);
        
        let p = [];
        if(matches.length>0) {
            for(let j=0;j<matches.length;j++) {
                p.push(generate_path(title, title, 'Title of course', 'title', matches[j][2].ssyk, matches[j][0], 'Occupation', 'Occupation', matches[j][1], 'Direct occupation', 1.0 ));
            }
        }
        
        paths.push(p);
    }

    return [res, paths];
}


function get_forecasts_Af_single(c) {
    
    let res = [];

    for(let j=0;j<c.length;j++) {

        let res_occupations = [];

        let occupations = c[j];

        for(let m=0;m<occupations.length;m++) {
            let ssyk = occupations[m]['Yrke']
            res_occupations.push(_ssyk2yrkesprognoser[ssyk])
        }

        res.push(res_occupations);
    }

    return res;
}

export async function get_forecasts_Af(courses, yrken) {

    let res_Regionala = [];

    for(let i=0;i<yrken.length;i++) {

        let res = get_forecasts_Af_single(yrken[i]);

        res = res.filter(x => x.length != 0); // delete empty (!)
        res_Regionala.push(res);
    }

    let [matches_direct, paths] = match_courses_by_title(courses);

    return [res_Regionala, matches_direct, paths];
}