import { generate_path } from '../utils/paths.js';
import { get_code_from_utb } from '../utils/codes.js';

import { _all_mappings, _all_mappingsCombined, _all_mappingsUtbgruppYrke, _all_tab0, _all_tab0Riket, _all_AKBAntal, _all_AKBTillgang} from "../utils/data.js"; 

//const fs = import('fs');

const api_forecast = 'https://api-prognos-utb.dinjobbframtid.se';
//const file_path = 'C:/Users/Simon/Documents/Projects/prognos_utbildning/library/version1/src/data/';

//
// Example mapping:
// https://api-prognos-utb.dinjobbframtid.se/mappings?code=213d

// ------------------------------------------------
// ISCED
// ------------------------------------------------
// ISCED 0: Early childhood education (‘less than primary’ for educational attainment)
// ISCED 1: Primary education
// ISCED 2: Lower secondary education
// ISCED 3: Upper secondary education
// ISCED 4: Post-secondary non-tertiary education
// ISCED 5: Short-cycle tertiary education
// ISCED 6: Bachelor’s or equivalent level
// ISCED 7: Master’s or equivalent level
// ISCED 8: Doctoral or equivalent level
// ------------------------------------------------

const levels = { 
    // Komvux, Folkhögskola
    'ISCED_2A': '2A', 'ISCED_3A': '3A',
    // Yrkeshögskolan, Konst- och kulturutbildningar
    'ISCED_4C': '4C', 'ISCED_5B': '5B', 
    // UH
    'grund': ['6','7','8'],'grundavancerad': ['6','7','8'], 'avancerad': ['6','7','8'] }

const levelsType = {
    'grund': 'UH'

    }

/*const levels_UH = ['6','7','8'];
const levels_YH = [4,5];
const levels_Komvux = [3];
const levels_Gym = [2];*/

//let _all_mappings = [];
/*
let _all_tab0 = []; // regional forecast 2035
let _all_tab0Riket = []; // national forecast 2035 

// Combined mappings:
// - Examen
// - AKB
// - Utbgrupper (Regionala)
let _all_mappingsCombined = [];


// Arbetskraftsbarometern
let _all_AKBTillgang = []; // regional forecast 2035
let _all_AKBAntal = []; // national forecast 2035

let _all_mappingsAKBUtbgrupp = [];
let _all_mappingsUtbgruppYrke = [];
*/

let _komvux_programs = [];
let _komvux_programs_3letters = [];
let _komvux_courses = [];


export async function initialize_SCB() {
    
    //let rawdata = (await fs).readFileSync(file_path + 'mappings.json')
    //_all_mappings = JSON.parse(rawdata);

    interpret_mapping(_all_mappings)

    // mapping combined [examen, akb, utbgrupper, komvux, komvux_courses]
    //rawdata = (await fs).readFileSync(file_path + 'mappingsCombined.json')
    //_all_mappingsCombined = JSON.parse(rawdata);
    
    // komvux programs
    _komvux_programs = _all_mappingsCombined.map(x=> {  if(x['komvux']!=null) 
                                                            return x['komvux'].split(',');
                                                        else return null;
                                                })

    _komvux_programs_3letters = _all_mappingsCombined.map(x=> {  if(x['komvux']!=null) 
                                                            return [...new Set(x['komvux'].split(',').map(y=>y.substring(0,3)))];
                                                        else return null;
                                                })

    // komvux courses
    _komvux_courses = _all_mappingsCombined.map(x=>{  if(x['komvux_courses']!=null) 
                                                        return x['komvux_courses'].split(',');
                                                      else return null; 
                                                    })

    // mapping AKB
    //url = api_forecast + '/mappingsAKB';
    //_all_mappingsAKBExamen = await (await fetch(url)).json();

    // mapping AKB Utbgrupp
    //url = api_forecast + '/mappingsAKBUtbgrupp';
    //_all_mappingsAKBUtbgrupp = await (await fetch(url)).json();

    // mapping Utbgrupp Yrke according to Regionala
    /*rawdata = (await fs).readFileSync(file_path + 'mappingsUtbgruppYrke.json')
    _all_mappingsUtbgruppYrke = JSON.parse(rawdata);
    
    // tab0
    rawdata = (await fs).readFileSync(file_path + 'tab0.json')
    _all_tab0 = JSON.parse(rawdata);

    // tab0Riket
    rawdata = (await fs).readFileSync(file_path + 'tab0Riket.json')
    _all_tab0Riket = JSON.parse(rawdata);

    // AKB: Antal
    rawdata = (await fs).readFileSync(file_path + 'AKBAntal.json')
    _all_AKBAntal = JSON.parse(rawdata);

    // AKB: Tillgång
    rawdata = (await fs).readFileSync(file_path + 'AKBTillgang.json')
    _all_AKBTillgang = JSON.parse(rawdata);*/
}


/*export async function fetch_all_mappings_from_API() {

    // mapping
    let url = api_forecast + '/mappings';
    _all_mappings = await (await fetch(url)).json();

    interpret_mapping(_all_mappings)

    // mapping combined [examen, akb, utbgrupper, komvux, komvux_courses]
    url = api_forecast + '/mappingsCombined';
    _all_mappingsCombined = await (await fetch(url)).json();

    // komvux programs
    _komvux_programs = _all_mappingsCombined.map(x=> {  if(x['komvux']!=null) 
                                                            return x['komvux'].split(',');
                                                        else return null;
                                                })

    _komvux_programs_3letters = _all_mappingsCombined.map(x=> {  if(x['komvux']!=null) 
                                                            return [...new Set(x['komvux'].split(',').map(y=>y.substring(0,3)))];
                                                        else return null;
                                                })

    // komvux courses
    _komvux_courses = _all_mappingsCombined.map(x=>{  if(x['komvux_courses']!=null) 
                                                        return x['komvux_courses'].split(',');
                                                      else return null; 
                                                    })

    // mapping AKB
    //url = api_forecast + '/mappingsAKB';
    //_all_mappingsAKBExamen = await (await fetch(url)).json();

    // mapping AKB Utbgrupp
    //url = api_forecast + '/mappingsAKBUtbgrupp';
    //_all_mappingsAKBUtbgrupp = await (await fetch(url)).json();

    // mapping Utbgrupp Yrke according to Regionala
    url = api_forecast + '/mappingsUtbgruppYrke';
    _all_mappingsUtbgruppYrke = await (await fetch(url)).json();

    // tab0
    url = api_forecast + '/tab0';
    _all_tab0 = await (await fetch(url)).json();

    // tab0Riket
    url = api_forecast + '/tab0Riket';
    _all_tab0Riket = await (await fetch(url)).json();

    // AKB: Antal
    url = api_forecast + '/AKBAntal';
    _all_AKBAntal = await (await fetch(url)).json();

    // AKB: Tillgång
    url = api_forecast + '/AKBTillgang';
    _all_AKBTillgang = await (await fetch(url)).json();
}*/

/* Check komvux code in order by:
    - programs
    - courses
    - program(s) by first 3 letters
*/
function filter_komvux(code) {

    let index = -1;
    let code_program = code;

    if(code[code.length-1] == 'R')
        code_program = code.substring(0,code.length-1) + 'N';
    
    // programs
    for(let i=0;i<_komvux_programs.length;i++) {
        if(_komvux_programs[i] !=null && _komvux_programs[i].includes(code_program))
            return [_all_mappingsCombined[i]];
    }

    // courses
    for(let i=0;i<_komvux_courses.length;i++) {
        if(_komvux_courses[i] !=null && _komvux_courses[i].includes(code))
            return [_all_mappingsCombined[i]];
    }

    // Program(s) by first 3 letters - allow multiple results
    code_program = code.substring(0,3);
    let res = []
    for(let i=0;i<_komvux_programs_3letters.length;i++) {
        if(_komvux_programs_3letters[i] !=null && _komvux_programs_3letters[i].includes(code_program))
            res.push(_all_mappingsCombined[i]);
    }
    
    return res
}

function komvux_code_included(s_programs, s_courses, code) {
    if(s_programs == null)
        return false;
    
    let programs = s_programs.split(',');

    // 1. Check exact program
    // replace regional coding with national coding
    if(code[code.length-1] == 'R')
        code = code.substring(0,code.length-1) + 'N';

    if(programs.includes(code))
        return true;
    
    // 2. Check exact course
    const courses = s_courses.split(',');
    if(courses.includes(code))
        return true;
    
    // 3. Check 3-letter group programs
    programs = programs.map(x=>x.substring(0,3))
    code = code.substring(0,3);
    
    if(programs.includes(code))
        return true;
    
    return false;
}

function codes2mapping_combined(codes, course, type = 'Examen') {
    
    let res = [];

    for(let i=0;i<codes.length;i++) {
        const code = codes[i]['code'];
        
        let r = null;
        
        if(type == 'Examen')
            r = _all_mappingsCombined.filter(x=> x[type] == code)
        else if(type == 'komvux') {
            r = filter_komvux(code)
        }

        if(r.length>1) {
            r = filter_by_subject(r,codes,course);
            //r = filter_by_level(r, course)
        }

        res.push(r);
    }

    return res;
}

function get_mapping_combined(codes, course, lansnamn, type = 'Examen') {

        let mappingAKB = [], mappingRegional = [], akb_codes = [], utbgrupper_codes = [];
        let forecasts_lan = [], forecasts_riket = [];

        let paths = [];

        let filtered_mappings = codes2mapping_combined(codes, course, type);

        for(let i=0;i<filtered_mappings.length;i++) {

            let r = filtered_mappings[i];
            
            for(let j=0;j<r.length;j++) {
                
                const akb_code = r[j]['AKB'];
                const utbgrupp_code = r[j]['Ubildningsgrupp-kod']
                const utbgrupp = r[j]['Utbildningsgrupp']

                mappingAKB.push({'code': codes[i]['code'], 'utbgrupp': akb_code });
                //if(mappingRegional.filter(x=> (x['code'] == codes[i]['code'] && x['utbgrupp'] == utbgrupp_code)).length == 0) // no duplicate mappings
                mappingRegional.push({'code': codes[i]['code'], 'utbgrupp': utbgrupp_code });
                
                akb_codes.push(akb_code);
                //if( !utbgrupper_codes.includes(utbgrupp_code)) { // no duplicate regional forecasts
                    utbgrupper_codes.push(utbgrupp_code)

                    let f_lan = _all_tab0.filter(x => x['Utbildningsgrupp'] == utbgrupp_code && x['Länsnamn'] == lansnamn)
                    let f_riket = _all_tab0Riket.filter(x => x['Utbildningsgrupp'] == utbgrupp_code)
                    forecasts_lan.push(f_lan)
                    forecasts_riket.push(f_riket)
                //}

                // PATHS
                // AKB
                const [f_tillgang, f_antal] = get_forecasts_AKB([akb_code]);
                let p = generate_path(codes[i]['code'], codes[i]['code'], "descr_input", type, 
                akb_code, akb_code, 'AKB', 'AKB', [{'description': 'AKB Tillgång', 'data': f_tillgang}, {'description': 'AKB Antal', 'data': f_antal } ],
                'AKB');
                
                paths.push(p)

                // Regional
                p = generate_path(codes[i]['code'], codes[i]['code'], "descr_input", type, 
                    utbgrupp_code, utbgrupp, 'Trender och prognoser 2035 forecasts in ' + lansnamn, 'Regional', [{'region': lansnamn, 'data': f_lan}, {'region': 'Riket', 'data': f_riket } ],
                    'Regional')
                paths.push(p)

                // Occupations
                const y = fetch_mappingYrke([utbgrupp_code], lansnamn)
                p = generate_path(codes[i]['code'], codes[i]['code'], "descr_input", type, 
                    utbgrupp_code, utbgrupp, 'Trender och prognoser 2035 occupations in ' + lansnamn, 'Regional_occupations', [ {'region': lansnamn, 'data': y} ],
                    'Regional occupations');
                
                paths.push(p);

            }
        }

        const [forecasts_tillgang, forecasts_antal] = get_forecasts_AKB(akb_codes);
        const yrken = fetch_mappingYrke(utbgrupper_codes)

        return [mappingAKB, mappingRegional, forecasts_tillgang, forecasts_antal, forecasts_lan, forecasts_riket, yrken, paths];
}

function filter_and_expand_utbgrupper(utbgrupp) {

    if(utbgrupp == null) { // if mapping is between specific code and an empty utbgrupp 
        return [];
    }

    return utbgrupp.split(',');
}

function get_mapping_by_subject(codes, lansnamn, course, do_filter_by_level = true) {
    
    let paths = [];

    // 3a. Regionala Utbildningsgrupper
    //let utbgrupper = [];
    const [mRegional, utbgrupper, f_riket, f_lan, paths_regional_and_occupations] = get_mapping_subjects(codes, lansnamn, course, do_filter_by_level);

    paths.push(...paths_regional_and_occupations)

    // 3b. AKB
    const [mAKB, f_tillgang, f_antal, paths_akb] = get_mapping_Utbgrupp_AKB(codes, utbgrupper, course);
    const y = fetch_mappingYrke(utbgrupper);

    paths.push(...paths_akb)
    
    return [utbgrupper, mAKB, mRegional, f_tillgang, f_antal, f_lan, f_riket, y, paths]
}

function isResultNull(mAKB) {

    if(mAKB == null || mAKB.length == 0)
        return true;

    for(let i=0;i<mAKB.length;i++) {
        if(mAKB[i]['utbgrupp'] != null)
            return false;
    }
    
    return true;
}

export async function get_mappings_and_forecasts(courses, lansnamn = 'Stockholms län') {

    let mappingsRegionala = [], mappingsAKB = [];
    let forecasts_lan = [], forecasts_riket = [];
    let forecasts_AKBTillgang = [], forecasts_AKBAntal = [];
    let yrken = [];
    let all_paths = [];

    for(let i=0;i<courses.length;i++) {

        let [paths, mAKB, mRegional, f_tillgang, f_antal, f_lan, f_riket, y] = [null, null, null, null, null, null, null, null];
        
        const course = courses[i];
        let codes = get_code_from_utb(course); // get all subject codes
        const codes_examen = codes.filter(x=>x['type'] == 'yrkesexamen');
        const codes_komvux = codes.filter(x=>x['type'] == 'komvux');

        if(codes_examen.length>0) { // 1. by examen
            [mAKB, mRegional, f_tillgang, f_antal, f_lan, f_riket, y, paths] = get_mapping_combined(codes_examen,course, lansnamn, 'Examen');
        }

        let isNullRes = isResultNull(mAKB); // continue if retrieved no hit from examen

        if(isNullRes && codes_komvux.length>0) { // 2. komvux
            [mAKB, mRegional, f_tillgang, f_antal, f_lan, f_riket, y, paths] = get_mapping_combined(codes_komvux,course, lansnamn, 'komvux');
        }
        else if(isNullRes) { // 3-4. by subject codes excl komvux

            let utbgrupper = [];

            // 3. - do filter by level
            [utbgrupper, mAKB, mRegional, f_tillgang, f_antal, f_lan, f_riket, y, paths] = get_mapping_by_subject(codes, lansnamn, course, true)

            // 4. if no hit, do without filter by level
            if(mRegional.length == 0 && mAKB.length == 0) {
                [utbgrupper, mAKB, mRegional, f_tillgang, f_antal, f_lan, f_riket, y, paths] = get_mapping_by_subject(codes, lansnamn, course, false)
            }
        }

        mappingsRegionala.push(mRegional)
        mappingsAKB.push(mAKB)

        forecasts_AKBTillgang.push(f_tillgang);
        forecasts_AKBAntal.push(f_antal);
        forecasts_riket.push(f_riket);
        forecasts_lan.push(f_lan);

        yrken.push(y);

        all_paths.push(paths)

        // reordered
    }
    
    return [mappingsRegionala, mappingsAKB, yrken, forecasts_riket, forecasts_lan, forecasts_AKBTillgang, forecasts_AKBAntal, all_paths]
}


function interpret_mapping(_all_mappings) {
    for(let i=0;i<_all_mappings.length;i++) {
        _all_mappings[i]['utbildningsniva'] = JSON.parse(_all_mappings[i]['utbildningsniva'] === null? null: _all_mappings[i]['utbildningsniva'].replaceAll("'",'"'));
        _all_mappings[i]['utbildningsniva-isced97'] = JSON.parse(_all_mappings[i]['utbildningsniva-isced97'] === null? null : _all_mappings[i]['utbildningsniva-isced97'].replaceAll("'",'"'));
    }
}



function get_isced(code) {
    if(code.includes('ISCED_')) { // yrkeshögskolan
        return code.replace('ISCED_','');
    }

    return code
}

// grund, grundavancerad, avancerad
function filter_by_level(r, course) {

    const form = course.info.form.code; // e.g. 'högskoleutbildning'
    let level_code = get_isced(course.info.educationLevel[0].code);
    const isced = levels[level_code];

    let filtered = [];
    
    for(let i=0;i<r.length;i++) {
        const niva_isced = r[i]['utbildningsniva-isced97'];
        
        if(niva_isced == null) {
            if(form == 'högskoleutbildning') // include
                filtered.push(r[i]);
            else{ // exclude (?)

            }
        }
        else {
            if(niva_isced.includes(level_code) || niva_isced.includes(level_code[0]) ) {   // must include exact or first digit ('5B' or '5')
                filtered.push(r[i]);
            }
        }
    }

    return filtered;
}

function filter_keywords(mappings, title, field_name) {
    
    let filtered_mappings = [];
    for(let i=0;i<mappings.length;i++) {
        
        let is_matched = true;
        if(field_name in mappings[i] && mappings[i][field_name] != null) { // keywords that must exist
            let keywords = mappings[i][field_name].split(',');

            for(let j=0;j<keywords.length;j++) {
                if(title.includes(keywords[j])) {
                    filtered_mappings.push(mappings[i])
                    break;
                }
            }
        }
    }

    return filtered_mappings
}

function filter_by_subject(mappings, codes, course) {

    let res = [];

    // get all subjects

    // filter by subject

    // filter by keywords
    const info = course.info;

    let title = info.title.string[0].content.toLowerCase();

    if(info.title.string.length>1) { // title in multiple languages 
        title = info.title.string.filter(x => x.lang == 'swe')[0].content.toLowerCase();
    }

    let filtered_mappings_must = filter_keywords(mappings, title, 'Keywords_must')
    
    if(filtered_mappings_must.length == 0) { // if no hits, remove the ones with mandatory keywords
        filtered_mappings_must = mappings;
        filtered_mappings_must = filtered_mappings_must.filter(x=> x['Keywords_must'] == null)
    }
    
    let filtered_mappings = filter_keywords(filtered_mappings_must, title, 'Keywords')

    if(filtered_mappings.length==0)
        filtered_mappings = filtered_mappings_must;

    return filtered_mappings;
}

export function fetch_mappingYrke(utbgrupper, lansnamn = 'Stockholms län') {

    let res = []
    for(let i=0;i<utbgrupper.length;i++) {
        const r = _all_mappingsUtbgruppYrke.filter(x => x['Utbildnings-grupp'] == utbgrupper[i] && x['Länsnamn'] == lansnamn);
        res.push(r)
    }

    return res;
}


function get_forecasts_AKB(codes) {

    let forecasts_tillgang = [];
    let forecasts_antal = [];

    for(let i=0;i<codes.length;i++) {
        let f_tillgang = _all_AKBTillgang.filter(x => x['Utbildningsgrupptext'] == codes[i]);
        let f_antal = _all_AKBAntal.filter(x => x['Utbildningsgrupptext'] == codes[i]);

        forecasts_antal.push(f_antal);
        forecasts_tillgang.push(f_tillgang);
    }

    return [forecasts_tillgang, forecasts_antal];
}

function get_mapping_Utbgrupp_AKB(codes, utbgrupper, course) {

    let res = [], akb_codes = [];
    let paths = []

    for(let i=0;i<utbgrupper.length;i++) {

        let r = _all_mappingsCombined.filter(x => x['Ubildningsgrupp-kod'] == utbgrupper[i]);//_all_mappingsAKBUtbgrupp.filter(x => x['Utbildningsgrupp-regionala'] == utbgrupper[i]);

        if(r.length>1) {
            r = filter_by_subject(r, utbgrupper, course);
        }

        for(let j=0;j<r.length;j++) {
            const akb_code = r[j]['AKB'];
            res.push({'code': utbgrupper[i], 'utbgrupp': akb_code });
            akb_codes.push(akb_code);

            // PATHS
            // AKB
            const [f_tillgang, f_antal] = get_forecasts_AKB([akb_code]);
                
            let p = generate_path(utbgrupper[i], utbgrupper[i], "AKB from subject", "utbgrupp by subject" + codes[i], 
                akb_code, akb_code, 'AKB', 'AKB', [{'description': 'AKB Tillgång', 'data': f_tillgang}, {'description': 'AKB Antal', 'data': f_antal } ],
                'AKB')
            paths.push(p)
        }
    }

    const [forecasts_tillgang, forecasts_antal] = get_forecasts_AKB(akb_codes);

    return [res, forecasts_tillgang, forecasts_antal, paths];
}

function get_mapping_subjects(subject_codes, lansnamn, course, do_filter_by_level = true) {

    let mRegional = [];
    let forecasts_lan = [];
    let forecasts_riket = [];
    let utbgrupp_codes = [];
    let paths = [];

    for(let i=0;i<subject_codes.length;i++) {
        const code = subject_codes[i]['code'];
        const type = subject_codes[i]['type'];

        let r = _all_mappings.filter(x => x['code'] == code && x['type'] == type);

        // filter by level
        if(do_filter_by_level)
            r = filter_by_level(r, course);

        for(let j=0;j<r.length;j++) {
            
            let utbgrupper = filter_and_expand_utbgrupper(r[j]['utbildningsgrupp-code']);
            
            for(let k=0;k<utbgrupper.length;k++) {

                let utbgrupp_code = utbgrupper[k];

                mRegional.push({'code': subject_codes[i]['code'], 'utbgrupp-code': utbgrupp_code, 'utbgrupp': utbgrupp_code + ', ' + r[j]['utbildningsgrupp'] });
                //res.push({'code': subject_codes[i]['code'], 'utbgrupp-code': r[j]['utbildningsgrupp-code'], 'utbgrupp': r[j]['utbildningsgrupp-code'] + ', ' + r[j]['utbildningsgrupp'] });

                //if(!utbgrupp_codes.includes(utbgrupp)) { // keep no duplicates
            
                    utbgrupp_codes.push(utbgrupp_code)
                    // corresponding forecasts
                    let f_lan = _all_tab0.filter(x => x['Utbildningsgrupp'] == utbgrupp_code && x['Länsnamn'] == lansnamn)
                    forecasts_lan.push(f_lan)
                    let f_riket = _all_tab0Riket.filter(x => x['Utbildningsgrupp'] == utbgrupp_code)
                    forecasts_riket.push(f_riket)
                //}

                // PATHS
                // Regional
                let p = generate_path(code, code, "descr_input", type, 
                utbgrupp_code, r[j]['utbildningsgrupp'], 'Trender och prognoser 2035 forecasts in ' + lansnamn, 'Regional', [{'region': lansnamn, 'data': f_lan}, {'region': 'Riket', 'data': f_riket } ],
                    'Regional');
                paths.push(p);

                // Occupations
                const y = fetch_mappingYrke([utbgrupp_code], lansnamn)
                p = generate_path(code, code, "descr_input", type,
                    utbgrupp_code, r[j]['utbildningsgrupp'], 'Trender och prognoser 2035 occupations in ' + lansnamn, 'Regional_occupations', [ {'region': lansnamn, 'data': y} ],
                    'Regional occupations')
                paths.push(p)
                
            }
        }
    }

    return [mRegional, utbgrupp_codes, forecasts_riket, forecasts_lan, paths]
}