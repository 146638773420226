import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCopy,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { fetch_course_all } from "../utils/fetch_data";
import { generate_assessment } from "../utils/assessment";
// import Lottie from "lottie-react";
// import loadingAnimation from "../assets/lottie/loading-animation.json";
import { Container, Row } from "react-bootstrap";
import { useSelectedCourse } from "../contexts/SelectedCourseContext";

import Spinner from "../components/Spinner";

export const Forecast = (props) => {
  const [courseCard, setCourseCard] = useState({ course: "Hämtar..." });
  const [forecastCard, setForecastCard] = useState();
  const [summary, setSummary] = useState("Genererar...");
  const [showFullText, setShowFullText] = useState(false);

  const [checkedDatabases, setCheckedDatabases] = useState({});
  const { updateFoundData, searchData, updateSearchData } = useSelectedCourse();
  const [isSearching, setIsSearching] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      if (props.courseId !== undefined) {
        console.log("fetching for courseId:", props.courseId);

        updateSearchData(true);

        const { course, forecast, trend_skills, paths_skills } =
          await fetch_course_all(props.courseId);
        console.log("course:", course);
        console.log("forecast:", forecast);
        setCourseCard(course);
        setForecastCard(forecast);

        // generate summary
        try {
          const assessment = await generate_assessment(
            course,
            forecast,
            trend_skills,
            "summary"
          );

          setIsSearching(false);
          setSummary(assessment.answer);
        } catch (error) {
          console.error(error);
        }

        updateSearchData(false);
      }
    } catch (error) {
      console.error(error);
      updateSearchData(false);
    }
  }, [props.courseId]);

  useEffect(() => {
    fetchData();

    // Initialize all databases as unchecked
    const initialCheckedDatabases = {};
    [
      "SCB-Labour-Market-Analysis",
      "SCB Trends",
      "Arbetsförmedlingens-work-forecast",
      "MyHs-education-reports",
      "Arbetsförmedlingens-Job-Ads",
    ].forEach((db) => {
      initialCheckedDatabases[db] = false;
    });
    setCheckedDatabases(initialCheckedDatabases);
  }, [fetchData]);

  useEffect(() => {
    // Simulate checking each database every second
    const interval = setInterval(() => {
      const updatedCheckedDatabases = { ...checkedDatabases };
      const uncheckedDatabases = Object.keys(checkedDatabases).filter(
        (db) => !checkedDatabases[db]
      );
      if (isSearching) {
        const randomDb =
          uncheckedDatabases[
            Math.floor(Math.random() * uncheckedDatabases.length)
          ];
        updatedCheckedDatabases[randomDb] = true;
        setCheckedDatabases(updatedCheckedDatabases);
      } else {
        clearInterval(interval);
        updateSearchData(false); // updateSearchData to false when all databases are marked as checked
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [checkedDatabases]);

  // const lottieStyle = {
  //   minWidth: '80px',
  //   maxWidth: '80px',
  //   marginLeft: '-30px',
  //   marginRight: '-30px',
  // };

  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Texten har kopierats");
    } catch (err) {
      console.error("Text not copied: ", err);
      toast.error("Texten kunde inte kopieras.");
    }
  };

  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };

  if (!searchData) {
    updateFoundData(true);
  }

  return (
    <section>
      <Container>
        <Row>
          <div className="step-content">
            <h1 className="mb-4">Utbildningsprognos</h1>

            <div className="card-style rounded-1 p-4 mb-4">
              <h2 className="mb-3">Din valda utbildning</h2>

              <div className="d-flex flex-column flex-md-row gap-3 mb-3">
                <div className="w-100">
                  <h4>Utbildningstitel</h4>
                  <p>{courseCard.course}</p>
                </div>

                <div className="w-100">
                  <h4>Plats</h4>
                  <p>{courseCard.school}</p>
                </div>
              </div>

              <div className="d-flex flex-column flex-md-row gap-3">
                <div className="w-100">
                  <h4>Poäng</h4>
                  <p>{courseCard.points}</p>
                </div>

                <div className="w-100">
                  <h4>Start</h4>
                  <p>{courseCard.start}</p>
                </div>
              </div>

              <div className="d-flex justify-content-center pt-5">
                <button
                  type="button"
                  onClick={() => props.goToStep(2)}
                  className="btn-outline-tsl"
                >
                  Välja en ny utbildning
                </button>
              </div>
            </div>

            {searchData ? (
              <div
                className="card-style rounded-1 p-4 mb-3"
                id="searching-in-databases"
              >
                <Row>
                  <Spinner />
                </Row>

                <div className="d-flex justify-content-center pt-5">
                  Vi hämtar data och genererar en summeringstext som ger
                  insikter i hur relevant utbildningen är i förhållande till
                  arbetsmarknaden. Det kan ta upp till en minut.
                </div>
                {/*<h2 className="mb-2">Fetching data from</h2>
                <Row className="gy-3">
                  {Object.keys(checkedDatabases).map((db, index) => (
                    <Col sm={12} md={6} className="d-flex align-items-center gap-3" key={index}>
                      <FontAwesomeIcon icon={checkedDatabases[db] ? faCheck : faCheck} className={checkedDatabases[db] ? '' : 'd-none'} />
                      {checkedDatabases[db] ? null : <Lottie animationData={loadingAnimation} loop={true} autoplay={true} style={lottieStyle} />}
                      <span>{db}</span>
                    </Col>
                  ))}
                </Row>*/}
              </div>
            ) : (
              <div className="card-style rounded-1 p-4 mb-3">
                <h2 className="mb-4">Din prognos</h2>
                <h4 className="mb-2">En summering.</h4>
                <p className="mb-2" style={{ whiteSpace: "pre-wrap" }}>
                  {showFullText ? summary : summary.substring(0, 350) + "..."}
                </p>

                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="link-tsl"
                    onClick={() =>
                      handleCopy(
                        showFullText ? summary : summary.substring(0, 350)
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} />
                    copy
                  </button>

                  <button
                    type="button"
                    onClick={handleToggleText}
                    className="link-tsl justify-content-end"
                  >
                    Visa {showFullText ? "mindre" : "mer"}{" "}
                    {showFullText ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </button>
                </div>
                <div className="d-flex justify-content-center pt-5">
                  <button
                    onClick={() => props.goToStep(4)}
                    className="btn-outline-tsl"
                  >
                    Visa en detaljerad prognos
                  </button>
                </div>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </section>
  );
};
