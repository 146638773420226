import { initialize_SCB, get_mappings_and_forecasts } from './forecasts/forecasts_akb_regional.js'
import { get_forecasts_Af } from './forecasts/forecasts_af.js';
import { initialize_ads, get_trends } from './forecasts/forecasts_trend.js';

import { initialize_Af } from './forecasts/forecasts_af.js';

import { initialize_MYH } from './forecasts/forecasts_myh.js';
import { get_forecasts_MYH } from './forecasts/forecasts_myh.js';
import { set_data } from './utils/data.js';

const api_url_susa = 'https://susanavet2.skolverket.se/api/1.1/';


export async function initialize(_all_mappings, _all_mappingsCombined, _all_mappingsUtbgruppYrke, 
  _all_tab0, _all_tab0Riket, _all_AKBAntal, _all_AKBTillgang, 
  _all_yrkesgrupper, _all_skills_trend, _all_rapporter) {

    set_data(_all_mappings, _all_mappingsCombined, _all_mappingsUtbgruppYrke, 
      _all_tab0, _all_tab0Riket, _all_AKBAntal, _all_AKBTillgang, 
      _all_yrkesgrupper, _all_skills_trend, _all_rapporter)

    await initialize_Af();
    await initialize_SCB();
    await initialize_ads();
    await initialize_MYH();
  }

// Fetch course data from Susa api
// - course: combined data
// - event, info, provider: parts
export async function fetch_course_susa(id) {

  const url_event = api_url_susa + 'events?id=' + id;
  const url_info = api_url_susa + 'infos?id=' + id;
  
  const js_event = await (await fetch(url_event)).json();
  const js_info = await (await fetch(url_info)).json();

  let event_provider = js_event.content[0].content.educationEvent.provider[0];
  const url_provider = api_url_susa + 'providers?id=' + event_provider;
  const js_provider = await (await fetch(url_provider)).json();

  return { 'course': combine_data(js_event, js_info, js_provider), 'event': js_event, 'info': js_info, 'provider': js_provider }
}


export async function get_forecasts_by_id(id) {
  const { course, event, info, provider } = await fetch_course_susa(id)

  return await get_forecasts(course)
}

// - Get all forecasts given a an event, info and provider.
//
// event, info, provider: Single response from Susa-navet
// from_disk: Specify if load mappings and forecasts from disk or from API
export async function get_forecasts(courses) {

  // generate forecast data
  const [mappings, mappingsAKB, yrken, forecasts_riket, forecasts_lan, forecasts_AKBTillgang, forecasts_AKBAntal, paths_akb_regional] = await get_mappings_and_forecasts(courses);
  const [forecasts_Af, af_by_title, paths_direct_occ] = await get_forecasts_Af(courses, yrken);
  const [trend_skills, paths_skills] = await get_trends(courses);
  const { myh } = await get_forecasts_MYH(courses)

  return { mappings: mappings, mappingsAKB: mappingsAKB, AKB_tillgang: forecasts_AKBTillgang, AKB_antal: forecasts_AKBAntal, Trender_riket: forecasts_riket, Trender_lan: forecasts_lan, Af_by_Trender: forecasts_Af, Af_by_title: af_by_title, skills_trend: trend_skills, skills_path: paths_skills, yrken: yrken, MYH: myh}
}

// map and combine incoming data into one array
function combine_data(js_events, js_infos, js_providers) {

  const id2provider = Object.fromEntries(js_providers.content.map(x => [x.content.educationProvider.identifier, x.content.educationProvider]));
  const id2info = Object.fromEntries(js_infos.content.map(x => [x.content.educationInfo.identifier, x.content.educationInfo]));

  let res = []
  for (let i = 0; i < js_events.content.length; i++) {

      const event = js_events.content[i].content.educationEvent;
      const info = id2info[js_events.content[i].content.educationEvent.education];

      if (js_events.content[i].content.educationEvent.provider.length > 1) {
          console.log('WARNNG: provider.length>1')
      }

      const provider = id2provider[js_events.content[i].content.educationEvent.provider[0]];

      let c = { 'event': event, 'info': info, 'provider': provider }

      res.push(c)
  }

  return res;
}
