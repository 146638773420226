import React, { Component } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";

import "./Filters.css";

import {
  get_values_kommuner,
  get_values_sprak,
  get_values_studieamnen,
  get_values_studieform,
  get_values_studietakt,
  get_values_anordnare,
} from "../../utils/valuestore.js";

import { DarkModeContext } from "../../App";

export default class Filters extends Component {
  selectRef = null;
  valueTest = null;

  selectDropdownStyle = (darkMode) => {
    return {
      control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: "#D3D3D4",
        "&:hover": {
          borderColor: "#D3D3D4",
        },
        backgroundColor: darkMode ? "#43474E" : "#ffffff",
        minWidth: 245,
        borderRadius: 1000,
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        maxHeight: 42,
        color: darkMode ? "#22262F" : "#ffffff",
        border: "2px solid #D3D3D4",
        cursor: "pointer",
        padding: "0 0 0 12px",
        transition: 0,
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused
            ? "#1E90FF"
            : darkMode
            ? "#43474E"
            : "#ffffff",
          color: isFocused ? "#ffffff" : darkMode ? "#ffffff" : "#22262F",
        };
      },
    };
  };

  constructor(props) {
    super(props);

    this.kommuner = get_values_kommuner();
    this.studietakt = get_values_studietakt();
    this.studieform = get_values_studieform();
    this.studieamne = get_values_studieamnen();
    this.sprak = get_values_sprak();
    this.anordnare = get_values_anordnare();

    this.clearAllValues = this.clearAllValues.bind(this);

    this.state = {
      moreFilters: false,
      anordnare: this.anordnare[0],
      region: this.kommuner[0],
      studietakt: this.studietakt[0],
      studieform: this.studieform[0],
      amne: this.studieamne[0],
      sprak: this.sprak[0],
    };
  }

  async clearAllValues(e) {
    e.preventDefault();

    this.setState({
      anordnare: this.anordnare[0],
      region: this.kommuner[0],
      studietakt: this.studietakt[0],
      studieform: this.studieform[0],
      amne: this.studieamne[0],
      sprak: this.sprak[0],
    });

    await this.props.resetFilters();
  }

  render() {
    return (
      <DarkModeContext.Consumer>
        {(darkMode) => (
          <Form className="d-flex flex-column gap-4">
            <span className="result-number">
              Antal träffar:{" "}
              <strong>
                {this.props.totalElements == 100000
                  ? ">" + this.props.totalElements
                  : this.props.totalElements}
              </strong>
            </span>

            <Form.Label>
              Använd filtren nedan för att specificera din sökning.
            </Form.Label>

            <Form.Group controlId="formRegion">
              <div className="d-flex align-items-center mb-2">
                <label>Kommun</label>

                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      "Kommun" filter anger i vilken kommun kursen hålls. Du får
                      avända fritext för att söka en kommun eller skrolla genom
                      listan. Det är mycket specifikt och tar inte hänsyn till
                      grannkommuner. Om du funderar på att studera på distans
                      rekommenderar vi att du endast använder "utbildningsform"
                      filtret och inte använder detta filter.
                    </Tooltip>
                  }
                >
                  <div className="tooltip-btn ms-2">
                    <FontAwesomeIcon icon={faQuestion} />
                  </div>
                </OverlayTrigger>
              </div>

              <Select
                value={this.props.region}
                className="advanced-form-select"
                defaultValue={this.kommuner[0]}
                styles={this.selectDropdownStyle(darkMode)}
                options={this.kommuner}
                onChange={(e) => {
                  this.props.handleChangeFilter(e, "filter_kommun");
                  console.log(e);
                  this.setState({ region: e });
                }}
                ref={(ref) => {
                  this.selectRef = ref;
                }}
              ></Select>
            </Form.Group>

            <Form.Group controlId="formLocal">
              <div className="d-flex align-items-center mb-2">
                <label>Studietakt</label>

                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Med "studietakt" filter kan du sortera kurserna baserat på
                      studietakten som har angivits för kursen.
                    </Tooltip>
                  }
                >
                  <div className="tooltip-btn ms-2">
                    <FontAwesomeIcon icon={faQuestion} />
                  </div>
                </OverlayTrigger>
              </div>

              <Select
                value={this.props.studietakt}
                isSearchable={false}
                className="advanced-form-select"
                defaultValue={this.studietakt[0]}
                styles={this.selectDropdownStyle(darkMode)}
                options={this.studietakt}
                onChange={(e) => {
                  this.props.handleChangeFilter(e, "filter_studietakt");
                  this.setState({ studietakt: e });
                }}
              ></Select>
            </Form.Group>

            <Form.Group controlId="formLocal">
              <div className="d-flex align-items-center mb-2">
                <label>Utbildningsform</label>

                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Med "utbildningsform" filter kan du välja kurser eller
                      program som erbjuder möjlighet att studera på distans
                      eller som kräver att du är närvarande fysiskt. Om du letar
                      efter kurser som erbjuder distansstudier rekommenderar vi
                      att du inte kombinerar detta filter med "kommun"-filtret.
                    </Tooltip>
                  }
                >
                  <div className="tooltip-btn ms-2">
                    <FontAwesomeIcon icon={faQuestion} />
                  </div>
                </OverlayTrigger>
              </div>

              <Select
                value={this.props.studieform}
                isSearchable={false}
                className="advanced-form-select"
                defaultValue={this.studieform[0]}
                styles={this.selectDropdownStyle(darkMode)}
                options={this.studieform}
                onChange={(e) => {
                  this.props.handleChangeFilter(e, "filter_studieform");
                  this.setState({ studieform: e });
                }}
              ></Select>
            </Form.Group>

            <Collapse in={this.state.moreFilters}>
              <div id="collapse-filters">
                <Form.Group controlId="formSubject" className="mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <label>Ämne</label>

                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          "Ämne" filter låter dig sortera kurserna utifrån
                          ämnesperspektiv. För att hitta alla kurser som har en
                          relation till ett ämne kan du välja ämnet, töm
                          sökfältet högst upp på sidan och sedan göra en ny
                          sökning.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </div>

                  <Select
                    value={this.props.amne}
                    ref={(ref) => {
                      this.selectRef2 = ref;
                    }}
                    defaultValue={this.studieamne[0]}
                    styles={this.selectDropdownStyle(darkMode)}
                    options={this.studieamne}
                    onChange={(e) => {
                      this.props.handleChangeFilter(e, "filter_studieamne");
                      this.setState({ amne: e });
                    }}
                  ></Select>
                </Form.Group>

                <Form.Group controlId="formInstitute" className="mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <label>Anordnare</label>

                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Detta filter sorterar kurser eller program baserat på
                          den studiekategori som skolan, som erbjuder kursen,
                          tillhör.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </div>

                  <Select
                    value={this.props.anordnare}
                    isSearchable={false}
                    className="advanced-form-select"
                    defaultValue={this.anordnare[0]}
                    styles={this.selectDropdownStyle(darkMode)}
                    options={this.anordnare}
                    onChange={(e) => {
                      this.props.handleChangeFilter(e, "filter_anordnare");
                      this.setState({ anordnare: e });
                    }}
                  ></Select>
                </Form.Group>

                <Form.Group controlId="formLanguage">
                  <div className="d-flex align-items-center mb-2">
                    <label>Språk</label>

                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          De flesta kurser är på svenska, men några har ett
                          annat språk som grund. I vårt filter har vi gjort det
                          möjligt att välja mellan de två mest dominerande
                          språkvalen, svenska och engelska.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-btn ms-2">
                        <FontAwesomeIcon icon={faQuestion} />
                      </div>
                    </OverlayTrigger>
                  </div>

                  <Select
                    value={this.props.sprak}
                    isSearchable={false}
                    className="advanced-form-select"
                    defaultValue={this.sprak[0]}
                    styles={this.selectDropdownStyle(darkMode)}
                    options={this.sprak}
                    onChange={(e) => {
                      this.props.handleChangeFilter(e, "filter_sprak");
                      this.setState({ sprak: e });
                    }}
                  ></Select>
                </Form.Group>
              </div>
            </Collapse>

            <button
              type="button"
              onClick={() =>
                this.setState({ moreFilters: !this.state.moreFilters })
              } //this.setMoreFilters(!this.moreFilters)}
              aria-controls="collapse-filters"
              aria-expanded={this.state.moreFilters}
              className="link-tsl"
            >
              Visa {this.state.moreFilters ? "färre" : "alla"} filter{" "}
              {this.state.moreFilters ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </button>

            <div className="d-flex">
              <button
                className="btn-outline-tsl"
                onClick={this.props.resetFilters}
              >
                Återställ alla filter
              </button>
            </div>
          </Form>
        )}
      </DarkModeContext.Consumer>
    );
  }
}
