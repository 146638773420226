

export async function get_data_by_fetch() {

    // AKB
    let _all_mappings = await(await fetch('./data/mappings.json')).json();
    let _all_mappingsCombined = await(await fetch('./data/mappingsCombined.json')).json();
    let _all_mappingsUtbgruppYrke = await(await fetch('./data/mappingsUtbgruppYrke.json')).json();
    let _all_tab0 = await(await fetch('./data/tab0.json')).json();
    let _all_tab0Riket = await(await fetch('./data/tab0Riket.json')).json();
    let _all_AKBAntal = await(await fetch('./data/AKBAntal.json')).json();
    let _all_AKBTillgang = await(await fetch('./data/AKBTillgang.json')).json();
    
    // Af
    let _all_yrkesgrupper = await(await fetch('./data/AfYrkesgrupp.json')).json();

    // Trends
    let _all_skills_trend = await(await fetch('./data/skills_trend.json')).json();
    
    // MYH
    let _all_rapporter = await(await fetch('./data/MYHRapporter.json')).json();

    return { _all_mappings, _all_mappingsCombined, _all_mappingsUtbgruppYrke, 
        _all_tab0, _all_tab0Riket, _all_AKBAntal, _all_AKBTillgang, 
        _all_yrkesgrupper, _all_skills_trend, _all_rapporter }
}