import { generate_course_cards } from "./generate_cards";
import { generate_forecast_cards } from "./generate_cards";

import { get_data_by_fetch } from '../libs/susa_forecast/src/get_data_fetch.mjs'

import { initialize, fetch_course_susa, get_forecasts } from "../libs/susa_forecast/src/index.mjs";

let course_card = null;
let forecast_card = null;
let trend_skills = null;
let paths_skills = null;

export async function fetch_course_all(courseId) {

    let { _all_mappings, _all_mappingsCombined, _all_mappingsUtbgruppYrke,
        _all_tab0, _all_tab0Riket, _all_AKBAntal, _all_AKBTillgang,
        _all_yrkesgrupper, _all_skills_trend, _all_rapporter } = await get_data_by_fetch()

    initialize(_all_mappings, _all_mappingsCombined, _all_mappingsUtbgruppYrke,
        _all_tab0, _all_tab0Riket, _all_AKBAntal, _all_AKBTillgang,
        _all_yrkesgrupper, _all_skills_trend, _all_rapporter)

    const { course, event, info, provider } = await fetch_course_susa(courseId)
    const { mappings, mappingsAKB, AKB_tillgang, AKB_antal, Trender_riket, Trender_lan, Af_by_Trender, Af_by_title, skills_trend, skills_path, yrken, MYH } = await get_forecasts(course, true);

    // generate cards
    course_card = generate_course_cards(course, mappings, Trender_lan, Trender_riket, mappingsAKB, AKB_antal, AKB_tillgang, yrken);
    forecast_card = generate_forecast_cards(Af_by_Trender, Af_by_title, mappings, Trender_lan, Trender_riket, mappingsAKB, AKB_antal, AKB_tillgang, yrken);

    trend_skills = skills_trend;
    paths_skills = skills_path;

    return { 'course': course_card[0], 'forecast': forecast_card[0], 'trend_skills': trend_skills, 'paths_skills': paths_skills }

}