import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { CDBStepper, CDBStep } from "cdbreact";
import StarterPage from './pages/StarterPage';
import { EducationCatalog } from './pages/Education-catalog/Education-catalog';
import { Forecast } from './pages/Forecast';
import { ForecastData } from './pages/ForecastData';
import { useSelectedCourse } from './contexts/SelectedCourseContext';
import { memo } from 'react';

import { EducationProvider } from './contexts/EducationContext';

export const DarkModeContext = createContext();

export default function App() {
  const { pathname } = useSelectedCourse();
  const [activeStep, setActiveStep] = useState(1);
  const [stepPositionUI, setStepPositionUI] = useState(1);

  const { foundData } = useSelectedCourse();
  const [darkMode, setDarkMode] = useState( localStorage.getItem('darkMode') === 'true' || false );

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const goToStep = (a) => {
    setActiveStep(a);
    
    if(a == 4)
      setStepPositionUI(3);
    else
      setStepPositionUI(a);
  };

  useEffect(() => {
    if (pathname) {
      setActiveStep(3);
      setStepPositionUI(3);
    }
  }, [pathname]);

  let componentToRender;
 
  switch (activeStep) {
    case 1:
      componentToRender = <StarterPage goToStep={goToStep} />;
      break;
    case 2:
      componentToRender = <EducationCatalog />;
      break;
    case 3:
      if (pathname) {
        componentToRender = <Forecast courseId={pathname} goToStep={goToStep} />;
      } else {
        componentToRender = <section><h2 className="text-center mx-3">Prognos inte tillgänglig</h2></section>;
      }
      break;
    case 4:
      if (foundData) {
        componentToRender = <ForecastData courseId={pathname} goToStep={goToStep}/>;
      } else {
        componentToRender = <section><h2 className="text-center mx-3">Prognos inte tillgänglig</h2></section>;
      }
      break;
    default:
      componentToRender = <section><h2 className="text-center mx-3">Hittades inte</h2></section>;
  }

  return (
    
    <DarkModeContext.Provider value={darkMode}>
      <EducationProvider>
      <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
        <div className="d-flex justify-content-end pt-4 pe-4">
          <input type="checkbox" onChange={toggleDarkMode} className="dark-mode-checkbox" id="dark-mode-checkbox" checked={darkMode} />
          
          <label for="dark-mode-checkbox" className ="dark-mode-toggle">
            <FontAwesomeIcon icon={faMoon} />
            <FontAwesomeIcon icon={faSun} />

            <span class="ball"></span>
          </label>
        </div>

        <CDBStepper
          direction="horizontal"
          className="stepper"
          activeStep={stepPositionUI}
        >
          <CDBStep
            id={1}
            icon="info"
            name="Start"
            handleClick={() => goToStep(1)}
            active={stepPositionUI}
          />

          <CDBStep
            id={2}
            icon="search"
            name="Utbildning"
            handleClick={() => goToStep(2)}
            active={stepPositionUI}
          />

          <CDBStep
            id={3}
            icon="book-reader"
            name="Prognos"
            handleClick={() => goToStep(3)}
            active={stepPositionUI}
            className={pathname ? '' : 'disabled-step'}
          />

          {/* <CDBStep
            id={4}
            icon="chart-pie"
            name="Data"
            handleClick={() => goToStep(4)}
            active={activeStep}
            className={foundData ? '' : 'disabled-step'}
          /> */}
        </CDBStepper>

        {componentToRender}
      </div>
      
    </EducationProvider>
    </DarkModeContext.Provider>

  );
} 