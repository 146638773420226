import React, { Component } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";

import "./Search.css";
import "./../Filters/Filters.css";
import { DarkModeContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.options = [
      {
        label: "Start: Våren 2023",
        value: "spring 2023",
      },
      {
        label: "Start: Sommar 2023",
        value: "summer 2023",
      },
      {
        label: "Start: Hösten 2023",
        value: "fall 2023",
      },
      {
        label: "Start: Våren 2024",
        value: "spring 2024",
      },
      {
        label: "Start: Sommar 2024",
        value: "summer 2024",
      },
      {
        label: "Start: Hösten 2024",
        value: "fall 2024",
      },
      {
        label: "Start: Alla tider",
        value: "anytime",
      },
    ];

    this.state = {
      search: "",
      start: this.options[2],
    };
  }

  getSelectSearchStyle = (darkMode) => {
    return {
      control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: "#D3D3D4",
        "&:hover": {
          borderColor: "#D3D3D4",
        },
        backgroundColor: darkMode ? "#43474E" : "#ffffff",
        minWidth: 245,
        borderRadius: 1000,
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        maxHeight: 42,
        color: darkMode ? "#22262F" : "#ffffff",
        border: "2px solid #D3D3D4",
        cursor: "pointer",
        transition: 0,
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused
            ? "#1E90FF"
            : darkMode
            ? "#43474E"
            : "#ffffff",
          color: isFocused ? "#ffffff" : darkMode ? "#ffffff" : "#22262F",
        };
      },
    };
  };

  render() {
    return (
      <DarkModeContext.Consumer>
        {(darkMode) => (
          <Form className="d-flex flex-column flex-md-row align-items-center gap-4">
            <Form.Group controlId="formFilter">
              <Select
                value={this.state.start}
                isSearchable={false}
                className="advanced-form-select"
                defaultValue={this.options[0]}
                styles={this.getSelectSearchStyle(darkMode)}
                options={this.options}
                onChange={(e) => {
                  this.props.handleChangeStart(e.value);
                  this.setState({ start: e });
                }}
              ></Select>
            </Form.Group>

            <Form.Group
              className="search-group position-relative"
              controlId="formSearch"
            >
              <Form.Control
                autoFocus
                value={this.props.search_string}
                onChange={(e) => this.props.handleChange(e)}
                type="text"
                placeholder="Sök på t ex logistiker"
              />

              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Det här är en fritextsökning. Det betyder att du kan söka
                    efter en kurs genom att skriva ett nyckelord som beskriver
                    kursens innehåll, till exempel 'sjuksköterska' eller
                    'systemutvecklare'. Denna katalog innehåller endast
                    offentligt finansierade kurser och program, men vi arbetar
                    också på att inkludera privata utbildningar.
                  </Tooltip>
                }
              >
                <div className="tooltip-btn position-absolute top-50 translate-middle-y">
                  <FontAwesomeIcon icon={faQuestion} />
                </div>
              </OverlayTrigger>
            </Form.Group>

            <button
              onClick={(e) => this.props.handleSubmit(e)}
              className="btn-outline-tsl search-btn"
            >
              Sök
            </button>
          </Form>
        )}
      </DarkModeContext.Consumer>
    );
  }
}
