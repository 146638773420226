// iso639-2 values, see https://www.loc.gov/standards/iso639-2/php/code_list.php
export function get_values_sprak() {
  return [
    { label: "Alla", value: "" },
    { label: "Svenska", value: "swe" },
    { label: "Engelska", value: "eng" },
  ];
}

export function get_values_studieform() {
  return [
    { label: "Alla", value: "" },
    { label: "Distans", value: "Distans" },
    { label: "På plats", value: "På plats" },
  ];
}

export function get_values_studietakt() {
  return [
    { label: "Alla", value: "" },
    { label: "100%", value: "100-100" },
    { label: "75-100%", value: "75-99" },
    { label: "50-75%", value: "50-74" },
    { label: "10-25%", value: "5-24" },
    { label: "0-10%", value: "0-9" },
  ];
}

// From susa references:
//         {"folkhögskola":4966,"grundläggande vuxenutbildning":45858,"grundskola":4779,
//          "gymnasial vuxenutbildning":321768,"gymnasieskola":11930,"gymnasiesärskola":718,"högskoleutbildning":166697,
//          "konst- och kulturutbildning":618,"kvalificerad yrkesutbildning":10,"sameskola":6,"sfi":334,"specialskola":11,
//          "särskola":677,"särvux":432,"utbildning med endast tillsyn":168,"vuxenutbildning":28,"yrkeshögskoleutbildning":4336}
export function get_values_anordnare() {
  // susa
  //return [{ label: "Alla", value: ""}, { label: "Yrkeshögskola", value: "yrkeshögskoleutbildning"}, { label: "Vuxenutbildning", value: "vuxenutbildning"}, { label: "Grundläggande vuxenutbildning", value: "grundläggande vuxenutbildning"}, { label: "Gymnasial vuxenutbildning", value: "gymnasial vuxenutbildning"}, { label: "Särvux", value: "särvux"}, { label: "Särskola", value: "särskola"},{ label: "Specialskola", value: "specialskola"}, { label: "Utbildning med endast tillsyn", value: "utbildning med endast tillsyn"}, { label: "Gymnasiesärskola", value: "gymnasiesärskola"}, { label: "Kvalificerad yrkesutbildning", value: "kvalificerad yrkesutbildning"}, { label: "Komvux", value: "komvux"}, { label: "Högskola och universitet", value: "högskoleutbildning"}, { label: "Folkhögskola", value: "Folkhögskola"}, { label: "Konst- och kulturutbildningar", value: "Konst- och kulturutbildningar"}, { label: "Sfi", value: "sfi"}, { label: "Specialskola", value: "specialskola"}, { label: "Sameskola", value: "sameskola"}];

  // ug
  return [
    { label: "Alla", value: "" },
    { label: "Yrkeshögskola", value: "Yrkeshögskola" },
    { label: "Komvux", value: "Komvux" },
    { label: "Högskola och universitet", value: "Högskola och universitet" },
    { label: "Folkhögskola", value: "Folkhögskola" },
    {
      label: "Konst- och kulturutbildningar",
      value: "Konst- och kulturutbildningar",
    },
  ];
}

const raw_kommuner = [
  { label: "Alla", value: "" },
  { label: "Upplands V\u00e4sby", value: "0114" },
  { label: "Vallentuna", value: "0115" },
  { label: "\u00d6ster\u00e5ker", value: "0117" },
  { label: "V\u00e4rmd\u00f6", value: "0120" },
  { label: "J\u00e4rf\u00e4lla", value: "0123" },
  { label: "Eker\u00f6", value: "0125" },
  { label: "Huddinge", value: "0126" },
  { label: "Botkyrka", value: "0127" },
  { label: "Salem", value: "0128" },
  { label: "Haninge", value: "0136" },
  { label: "Tyres\u00f6", value: "0138" },
  { label: "Upplands-Bro", value: "0139" },
  { label: "Nykvarn", value: "0140" },
  { label: "T\u00e4by", value: "0160" },
  { label: "Danderyd", value: "0162" },
  { label: "Sollentuna", value: "0163" },
  { label: "Stockholm", value: "0180" },
  { label: "S\u00f6dert\u00e4lje", value: "0181" },
  { label: "Nacka", value: "0182" },
  { label: "Sundbyberg", value: "0183" },
  { label: "Solna", value: "0184" },
  { label: "Liding\u00f6", value: "0186" },
  { label: "Vaxholm", value: "0187" },
  { label: "Norrt\u00e4lje", value: "0188" },
  { label: "Sigtuna", value: "0191" },
  { label: "Nyn\u00e4shamn", value: "0192" },
  { label: "H\u00e5bo", value: "0305" },
  { label: "\u00c4lvkarleby", value: "0319" },
  { label: "Knivsta", value: "0330" },
  { label: "Heby", value: "0331" },
  { label: "Tierp", value: "0360" },
  { label: "Uppsala", value: "0380" },
  { label: "Enk\u00f6ping", value: "0381" },
  { label: "\u00d6sthammar", value: "0382" },
  { label: "Ving\u00e5ker", value: "0428" },
  { label: "Gnesta", value: "0461" },
  { label: "Nyk\u00f6ping", value: "0480" },
  { label: "Oxel\u00f6sund", value: "0481" },
  { label: "Flen", value: "0482" },
  { label: "Katrineholm", value: "0483" },
  { label: "Eskilstuna", value: "0484" },
  { label: "Str\u00e4ngn\u00e4s", value: "0486" },
  { label: "Trosa", value: "0488" },
  { label: "\u00d6desh\u00f6g", value: "0509" },
  { label: "Ydre", value: "0512" },
  { label: "Kinda", value: "0513" },
  { label: "Boxholm", value: "0560" },
  { label: "\u00c5tvidaberg", value: "0561" },
  { label: "Finsp\u00e5ng", value: "0562" },
  { label: "Valdemarsvik", value: "0563" },
  { label: "Link\u00f6ping", value: "0580" },
  { label: "Norrk\u00f6ping", value: "0581" },
  { label: "S\u00f6derk\u00f6ping", value: "0582" },
  { label: "Motala", value: "0583" },
  { label: "Vadstena", value: "0584" },
  { label: "Mj\u00f6lby", value: "0586" },
  { label: "Aneby", value: "0604" },
  { label: "Gnosj\u00f6", value: "0617" },
  { label: "Mullsj\u00f6", value: "0642" },
  { label: "Habo", value: "0643" },
  { label: "Gislaved", value: "0662" },
  { label: "Vaggeryd", value: "0665" },
  { label: "J\u00f6nk\u00f6ping", value: "0680" },
  { label: "N\u00e4ssj\u00f6", value: "0682" },
  { label: "V\u00e4rnamo", value: "0683" },
  { label: "S\u00e4vsj\u00f6", value: "0684" },
  { label: "Vetlanda", value: "0685" },
  { label: "Eksj\u00f6", value: "0686" },
  { label: "Tran\u00e5s", value: "0687" },
  { label: "Uppvidinge", value: "0760" },
  { label: "Lessebo", value: "0761" },
  { label: "Tingsryd", value: "0763" },
  { label: "Alvesta", value: "0764" },
  { label: "\u00c4lmhult", value: "0765" },
  { label: "Markaryd", value: "0767" },
  { label: "V\u00e4xj\u00f6", value: "0780" },
  { label: "Ljungby", value: "0781" },
  { label: "H\u00f6gsby", value: "0821" },
  { label: "Tors\u00e5s", value: "0834" },
  { label: "M\u00f6rbyl\u00e5nga", value: "0840" },
  { label: "Hultsfred", value: "0860" },
  { label: "M\u00f6nster\u00e5s", value: "0861" },
  { label: "Emmaboda", value: "0862" },
  { label: "Kalmar", value: "0880" },
  { label: "Nybro", value: "0881" },
  { label: "Oskarshamn", value: "0882" },
  { label: "V\u00e4stervik", value: "0883" },
  { label: "Vimmerby", value: "0884" },
  { label: "Borgholm", value: "0885" },
  { label: "Gotland", value: "0980" },
  { label: "Olofstr\u00f6m", value: "1060" },
  { label: "Karlskrona", value: "1080" },
  { label: "Ronneby", value: "1081" },
  { label: "Karlshamn", value: "1082" },
  { label: "S\u00f6lvesborg", value: "1083" },
  { label: "Sval\u00f6v", value: "1214" },
  { label: "Staffanstorp", value: "1230" },
  { label: "Burl\u00f6v", value: "1231" },
  { label: "Vellinge", value: "1233" },
  { label: "\u00d6stra G\u00f6inge", value: "1256" },
  { label: "\u00d6rkelljunga", value: "1257" },
  { label: "Bjuv", value: "1260" },
  { label: "K\u00e4vlinge", value: "1261" },
  { label: "Lomma", value: "1262" },
  { label: "Svedala", value: "1263" },
  { label: "Skurup", value: "1264" },
  { label: "Sj\u00f6bo", value: "1265" },
  { label: "H\u00f6rby", value: "1266" },
  { label: "H\u00f6\u00f6r", value: "1267" },
  { label: "Tomelilla", value: "1270" },
  { label: "Brom\u00f6lla", value: "1272" },
  { label: "Osby", value: "1273" },
  { label: "Perstorp", value: "1275" },
  { label: "Klippan", value: "1276" },
  { label: "\u00c5storp", value: "1277" },
  { label: "B\u00e5stad", value: "1278" },
  { label: "Malm\u00f6", value: "1280" },
  { label: "Lund", value: "1281" },
  { label: "Landskrona", value: "1282" },
  { label: "Helsingborg", value: "1283" },
  { label: "H\u00f6gan\u00e4s", value: "1284" },
  { label: "Esl\u00f6v", value: "1285" },
  { label: "Ystad", value: "1286" },
  { label: "Trelleborg", value: "1287" },
  { label: "Kristianstad", value: "1290" },
  { label: "Simrishamn", value: "1291" },
  { label: "\u00c4ngelholm", value: "1292" },
  { label: "H\u00e4ssleholm", value: "1293" },
  { label: "Hylte", value: "1315" },
  { label: "Halmstad", value: "1380" },
  { label: "Laholm", value: "1381" },
  { label: "Falkenberg", value: "1382" },
  { label: "Varberg", value: "1383" },
  { label: "Kungsbacka", value: "1384" },
  { label: "H\u00e4rryda", value: "1401" },
  { label: "Partille", value: "1402" },
  { label: "\u00d6cker\u00f6", value: "1407" },
  { label: "Stenungsund", value: "1415" },
  { label: "Tj\u00f6rn", value: "1419" },
  { label: "Orust", value: "1421" },
  { label: "Soten\u00e4s", value: "1427" },
  { label: "Munkedal", value: "1430" },
  { label: "Tanum", value: "1435" },
  { label: "Dals-Ed", value: "1438" },
  { label: "F\u00e4rgelanda", value: "1439" },
  { label: "Ale", value: "1440" },
  { label: "Lerum", value: "1441" },
  { label: "V\u00e5rg\u00e5rda", value: "1442" },
  { label: "Bollebygd", value: "1443" },
  { label: "Gr\u00e4storp", value: "1444" },
  { label: "Essunga", value: "1445" },
  { label: "Karlsborg", value: "1446" },
  { label: "Gullsp\u00e5ng", value: "1447" },
  { label: "Tranemo", value: "1452" },
  { label: "Bengtsfors", value: "1460" },
  { label: "Mellerud", value: "1461" },
  { label: "Lilla Edet", value: "1462" },
  { label: "Mark", value: "1463" },
  { label: "Svenljunga", value: "1465" },
  { label: "Herrljunga", value: "1466" },
  { label: "Vara", value: "1470" },
  { label: "G\u00f6tene", value: "1471" },
  { label: "Tibro", value: "1472" },
  { label: "T\u00f6reboda", value: "1473" },
  { label: "G\u00f6teborg", value: "1480" },
  { label: "M\u00f6lndal", value: "1481" },
  { label: "Kung\u00e4lv", value: "1482" },
  { label: "Lysekil", value: "1484" },
  { label: "Uddevalla", value: "1485" },
  { label: "Str\u00f6mstad", value: "1486" },
  { label: "V\u00e4nersborg", value: "1487" },
  { label: "Trollh\u00e4ttan", value: "1488" },
  { label: "Alings\u00e5s", value: "1489" },
  { label: "Bor\u00e5s", value: "1490" },
  { label: "Ulricehamn", value: "1491" },
  { label: "\u00c5m\u00e5l", value: "1492" },
  { label: "Mariestad", value: "1493" },
  { label: "Lidk\u00f6ping", value: "1494" },
  { label: "Skara", value: "1495" },
  { label: "Sk\u00f6vde", value: "1496" },
  { label: "Hjo", value: "1497" },
  { label: "Tidaholm", value: "1498" },
  { label: "Falk\u00f6ping", value: "1499" },
  { label: "Kil", value: "1715" },
  { label: "Eda", value: "1730" },
  { label: "Torsby", value: "1737" },
  { label: "Storfors", value: "1760" },
  { label: "Hammar\u00f6", value: "1761" },
  { label: "Munkfors", value: "1762" },
  { label: "Forshaga", value: "1763" },
  { label: "Grums", value: "1764" },
  { label: "\u00c5rj\u00e4ng", value: "1765" },
  { label: "Sunne", value: "1766" },
  { label: "Karlstad", value: "1780" },
  { label: "Kristinehamn", value: "1781" },
  { label: "Filipstad", value: "1782" },
  { label: "Hagfors", value: "1783" },
  { label: "Arvika", value: "1784" },
  { label: "S\u00e4ffle", value: "1785" },
  { label: "Lekeberg", value: "1814" },
  { label: "Lax\u00e5", value: "1860" },
  { label: "Hallsberg", value: "1861" },
  { label: "Degerfors", value: "1862" },
  { label: "H\u00e4llefors", value: "1863" },
  { label: "Ljusnarsberg", value: "1864" },
  { label: "\u00d6rebro", value: "1880" },
  { label: "Kumla", value: "1881" },
  { label: "Askersund", value: "1882" },
  { label: "Karlskoga", value: "1883" },
  { label: "Nora", value: "1884" },
  { label: "Lindesberg", value: "1885" },
  { label: "Skinnskatteberg", value: "1904" },
  { label: "Surahammar", value: "1907" },
  { label: "Kungs\u00f6r", value: "1960" },
  { label: "Hallstahammar", value: "1961" },
  { label: "Norberg", value: "1962" },
  { label: "V\u00e4ster\u00e5s", value: "1980" },
  { label: "Sala", value: "1981" },
  { label: "Fagersta", value: "1982" },
  { label: "K\u00f6ping", value: "1983" },
  { label: "Arboga", value: "1984" },
  { label: "Vansbro", value: "2021" },
  { label: "Malung-S\u00e4len", value: "2023" },
  { label: "Gagnef", value: "2026" },
  { label: "Leksand", value: "2029" },
  { label: "R\u00e4ttvik", value: "2031" },
  { label: "Orsa", value: "2034" },
  { label: "\u00c4lvdalen", value: "2039" },
  { label: "Smedjebacken", value: "2061" },
  { label: "Mora", value: "2062" },
  { label: "Falun", value: "2080" },
  { label: "Borl\u00e4nge", value: "2081" },
  { label: "S\u00e4ter", value: "2082" },
  { label: "Hedemora", value: "2083" },
  { label: "Avesta", value: "2084" },
  { label: "Ludvika", value: "2085" },
  { label: "Ockelbo", value: "2101" },
  { label: "Hofors", value: "2104" },
  { label: "Ovan\u00e5ker", value: "2121" },
  { label: "Nordanstig", value: "2132" },
  { label: "Ljusdal", value: "2161" },
  { label: "G\u00e4vle", value: "2180" },
  { label: "Sandviken", value: "2181" },
  { label: "S\u00f6derhamn", value: "2182" },
  { label: "Bolln\u00e4s", value: "2183" },
  { label: "Hudiksvall", value: "2184" },
  { label: "\u00c5nge", value: "2260" },
  { label: "Timr\u00e5", value: "2262" },
  { label: "H\u00e4rn\u00f6sand", value: "2280" },
  { label: "Sundsvall", value: "2281" },
  { label: "Kramfors", value: "2282" },
  { label: "Sollefte\u00e5", value: "2283" },
  { label: "\u00d6rnsk\u00f6ldsvik", value: "2284" },
  { label: "Ragunda", value: "2303" },
  { label: "Br\u00e4cke", value: "2305" },
  { label: "Krokom", value: "2309" },
  { label: "Str\u00f6msund", value: "2313" },
  { label: "\u00c5re", value: "2321" },
  { label: "Berg", value: "2326" },
  { label: "H\u00e4rjedalen", value: "2361" },
  { label: "\u00d6stersund", value: "2380" },
  { label: "Nordmaling", value: "2401" },
  { label: "Bjurholm", value: "2403" },
  { label: "Vindeln", value: "2404" },
  { label: "Robertsfors", value: "2409" },
  { label: "Norsj\u00f6", value: "2417" },
  { label: "Mal\u00e5", value: "2418" },
  { label: "Storuman", value: "2421" },
  { label: "Sorsele", value: "2422" },
  { label: "Dorotea", value: "2425" },
  { label: "V\u00e4nn\u00e4s", value: "2460" },
  { label: "Vilhelmina", value: "2462" },
  { label: "\u00c5sele", value: "2463" },
  { label: "Ume\u00e5", value: "2480" },
  { label: "Lycksele", value: "2481" },
  { label: "Skellefte\u00e5", value: "2482" },
  { label: "Arvidsjaur", value: "2505" },
  { label: "Arjeplog", value: "2506" },
  { label: "Jokkmokk", value: "2510" },
  { label: "\u00d6verkalix", value: "2513" },
  { label: "Kalix", value: "2514" },
  { label: "\u00d6vertorne\u00e5", value: "2518" },
  { label: "Pajala", value: "2521" },
  { label: "G\u00e4llivare", value: "2523" },
  { label: "\u00c4lvsbyn", value: "2560" },
  { label: "Lule\u00e5", value: "2580" },
  { label: "Pite\u00e5", value: "2581" },
  { label: "Boden", value: "2582" },
  { label: "Haparanda", value: "2583" },
  { label: "Kiruna", value: "2584" },
];

function customSort(a, b) {
  if (a.label === "Alla") return -1;
  if (b.label === "Alla") return 1;
  return a.label.localeCompare(b.label);
}

export function get_values_kommuner() {
  const res = raw_kommuner.sort(customSort).map((x) => {
    return { label: x["label"], value: x["label"] };
  });

  return res;
}

// will be changed to hit api
export function get_values_studieamnen() {
  return [
    { label: "Alla", value: "" },
    { label: "Friskv\u00e5rd och h\u00e4lsopedagogik", value: 32 },
    { label: "Fotv\u00e5rd", value: 33 },
    { label: "Hudv\u00e5rd och spaterapi", value: 28 },
    { label: "Massage", value: 29 },
    { label: "Friluftsliv", value: 30 },
    { label: "\u00d6vrigt inom frisk och sk\u00f6nhetsv\u00e5rd", value: 31 },
    { label: "Tr\u00e4hantverk", value: 34 },
    { label: "Metall och smide", value: 35 },
    { label: "Konsthantverk", value: 36 },
    { label: "Fris\u00f6r", value: 37 },
    { label: "Bageri", value: 39 },
    { label: "Skinnhantverk", value: 40 },
    { label: "B\u00e5tbyggeri", value: 41 },
    { label: "Textilhantverk", value: 42 },
    { label: "\u00d6vrigt inom hantverk", value: 43 },
    { label: "Ljudteknik", value: 210 },
    { label: "Medieproduktion", value: 53 },
    { label: "Grafisk design", value: 54 },
    { label: "Journalistik", value: 56 },
    { label: "Kommunikationsvetenskap", value: 57 },
    { label: "TV och radioproduktion", value: 58 },
    { label: "Bibliotek och informationsvetenskap", value: 59 },
    { label: "Djurv\u00e5rd och h\u00e4sth\u00e5llning", value: 116 },
    { label: "Viltv\u00e5rd och jakt", value: 117 },
    { label: "Lant- och skogsbruk", value: 118 },
    { label: "Fiske och vattenbruk", value: 119 },
    { label: "Tr\u00e4dg\u00e5rd och landskap", value: 120 },
    { label: "Agronom", value: 121 },
    { label: "\u00d6vrigt inom naturbruk", value: 122 },
    { label: "Djursjukv\u00e5rd", value: 203 },
    { label: "Veterin\u00e4r", value: 108 },
    { label: "Bist\u00e5nd och U-l\u00e4nder", value: 132 },
    { label: "Statsvetenskap", value: 133 },
    { label: "Psykologi", value: 134 },
    { label: "Socialt inriktade utbildningar", value: 135 },
    { label: "Juridik", value: 136 },
    {
      label: "\u00d6vrigt inom samh\u00e4llsvetenskap och juridik",
      value: 137,
    },
    { label: "Genusperspektiv", value: 202 },
    { label: "Statistik", value: 138 },
    { label: "Samh\u00e4llsvetenskap", value: 139 },
    { label: "Sociologi", value: 140 },
    {
      label: "\u00d6vrigt inom s\u00e4kerhet och r\u00e4ddningstj\u00e4nst",
      value: 144,
    },
    { label: "Bevakning och larm", value: 141 },
    { label: "Brand och r\u00e4ddningstj\u00e4nst", value: 142 },
    { label: "Officer", value: 207 },
    { label: "Polis", value: 143 },
    { label: "Lantm\u00e4teriteknik", value: 160 },
    { label: "Byggnadsteknik", value: 161 },
    { label: "Teknisk fysik", value: 162 },
    { label: "Arkitektur", value: 4 },
    { label: "\u00d6vrigt inom teknik", value: 145 },
    { label: "Design och produktutveckling", value: 146 },
    { label: "Elektroteknik", value: 147 },
    { label: "Industriell ekonomi", value: 148 },
    { label: "Information- och kommunikationsteknik", value: 149 },
    { label: "Farkostteknik", value: 150 },
    { label: "Datateknik", value: 151 },
    { label: "Samh\u00e4llsbyggnadsteknik", value: 152 },
    { label: "Milj\u00f6 och energiteknik", value: 153 },
    { label: "Ljudteknik", value: 154 },
    { label: "Kemiteknik", value: 155 },
    { label: "Materialteknik", value: 156 },
    { label: "Rymdteknik", value: 157 },
    { label: "Maskinteknik", value: 158 },
    { label: "Medicinsk teknik", value: 159 },
    { label: "Fastighetssk\u00f6tsel", value: 163 },
    { label: "El", value: 164 },
    { label: "Industri och produktion", value: 165 },
    { label: "Papper", value: 166 },
    { label: "Tr\u00e4/tillverkning och underh\u00e5ll", value: 167 },
    { label: "Fordon", value: 168 },
    { label: "\u00d6vrigt inom tillverkning och underh\u00e5ll", value: 169 },
    { label: "Textil", value: 170 },
    { label: "Livsmedel", value: 171 },
    { label: "Kyl- och VVS teknik", value: 172 },
    { label: "Svetsteknik", value: 173 },
    { label: "Grafisk, processteknik", value: 174 },
    { label: "\u00d6vrigt inom transport", value: 176 },
    { label: "Transportledare", value: 177 },
    { label: "Transportlogistik", value: 178 },
    { label: "J\u00e4rnv\u00e4g", value: 179 },
    { label: "Flyg", value: 180 },
    { label: "Buss/Lastbil/Maskinf\u00f6rare", value: 181 },
    { label: "Sj\u00f6fart", value: 175 },
    { label: "Specialpedagogik", value: 192 },
    { label: "Pedagogik", value: 193 },
    { label: "Folkh\u00f6gskoll\u00e4rare", value: 205 },
    { label: "Grundl\u00e4rare och fritidspedagog", value: 182 },
    { label: "Fritidsledare", value: 183 },
    { label: "Dramapedagog", value: 184 },
    { label: "Idrott", value: 186 },
    { label: "\u00c4mnesl\u00e4rare", value: 187 },
    { label: "\u00d6vrigt inom undervisning och idrott", value: 188 },
    { label: "Studie- och yrkesv\u00e4gledare", value: 189 },
    { label: "Yrkesl\u00e4rare", value: 190 },
    { label: "F\u00f6rskoll\u00e4rare", value: 191 },
    { label: "Utbildningar f\u00f6r funktionshindrade", value: 194 },
    { label: "F\u00f6rutbildningar", value: 195 },
    { label: "\u00d6vrigt inom f\u00f6rberedande utbildningar", value: 196 },
    { label: "Kurser f\u00f6r invandrare", value: 197 },
    { label: "\u00d6vriga kurser", value: 200 },
    { label: "\u00d6vrigt tv\u00e4rvetenskap", value: 201 },
    { label: "Datavetenskap", value: 208 },
    { label: "Informatik och systemvetenskap", value: 17 },
    { label: "Datateknik", value: 151 },
    { label: "Programmeringsteknik", value: 10 },
    { label: "\u00d6vrigt inom data och IT", value: 11 },
    { label: "Databaser", value: 12 },
    { label: "Datoranv\u00e4ndning", value: 13 },
    { label: "Webbdesign", value: 14 },
    { label: "Spelutveckling", value: 15 },
    { label: "Anl\u00e4ggning (mark, v\u00e4g, vatten)", value: 1 },
    { label: "Snickeri och tr\u00e4", value: 3 },
    { label: "Arkitektur", value: 4 },
    { label: "\u00d6vrigt inom bygg och anl\u00e4ggning", value: 5 },
    { label: "Byggnadsv\u00e5rd", value: 6 },
    { label: "Fastighetsf\u00f6rvaltning", value: 7 },
    { label: "Byggteknik", value: 8 },
    { label: "Bergteknik", value: 9 },
    { label: "Nationalekonomi", value: 18 },
    { label: "Logistik", value: 19 },
    { label: "Marknadsf\u00f6ring", value: 20 },
    { label: "\u00d6vrigt inom ekonomi och marknadsf\u00f6ring", value: 21 },
    { label: "Ekonomi", value: 22 },
    { label: "Redovisning", value: 23 },
    { label: "F\u00f6rs\u00e4ljning", value: 24 },
    { label: "F\u00f6rvaltning", value: 25 },
    { label: "Management och ledarskap", value: 26 },
    { label: "Administration", value: 27 },
    { label: "\u00d6vrigt inom hotell, restaurang och turism", value: 48 },
    { label: "Restaurang och storhush\u00e5ll", value: 49 },
    { label: "Kost", value: 50 },
    { label: "Eventproduktion", value: 51 },
    { label: "Turism och resor", value: 45 },
    { label: "Ledarskap inom turism", value: 46 },
    { label: "Hotell", value: 47 },
    { label: "Keramik och glas", value: 64 },
    { label: "S\u00e5ng", value: 65 },
    { label: "Dans", value: 66 },
    { label: "Teater (drama)", value: 67 },
    { label: "Inredningsarkitektur", value: 68 },
    { l: 99 },
    { label: "Svenska", value: 100 },
    { label: "Lingvistik/Allm\u00e4nspr\u00e5kvetenskap", value: 101 },
    { label: "Ryska", value: 80 },
    { label: "Finska", value: 81 },
    { label: "Engelska", value: 82 },
    { label: "Jiddisch", value: 211 },
    { label: "Grekiska", value: 84 },
    { label: "Samiska", value: 212 },
    { label: "Kinesiska", value: 85 },
    { label: "\u00d6vers\u00e4ttare", value: 86 },
    { label: "Italienska", value: 87 },
    { label: "\u00d6vriga spr\u00e5k", value: 88 },
    { label: "Teckenspr\u00e5k", value: 89 },
    { label: "Tyska", value: 90 },
    { label: "Spanska", value: 91 },
    { label: "Portugisiska", value: 92 },
    { label: "\u00d6vriga nordiska spr\u00e5k", value: 93 },
    { label: "Japanska", value: 94 },
    { label: "Arabiska", value: 95 },
    { label: "Medicin", value: 102 },
    { label: "Sjuksk\u00f6terska", value: 103 },
    { label: "H\u00e4lso- och sjukv\u00e5rdadministration", value: 104 },
    { label: "H\u00e4lsa och samh\u00e4lle", value: 105 },
    { label: "Omv\u00e5rdnad", value: 106 },
    { label: "Dietist", value: 107 },
    { label: "Optiker", value: 204 },
    { label: "Psykoterapi", value: 109 },
    { label: "Medicinsk fysik", value: 206 },
    { label: "\u00d6vrigt inom medicin och v\u00e5rd", value: 110 },
    { label: "Tal och h\u00f6rsel", value: 111 },
    { label: "Tandv\u00e5rd", value: 112 },
    { label: "Fysioterapi och rehabilitering", value: 113 },
    { label: "Biomedicin", value: 114 },
    { label: "L\u00e4kare", value: 115 },
    { label: "Farmakologi", value: 123 },
    { label: "Matematik", value: 128 },
    { label: "Milj\u00f6", value: 129 },
    { label: "Kemi", value: 130 },
    { label: "Biologi", value: 131 },
    { label: "Farmakologi", value: 123 },
    { label: "\u00d6vrigt inom naturvetenskap", value: 124 },
    { label: "Geovetenskap", value: 125 },
    { label: "Geografi", value: 126 },
    { label: "Fysik", value: 127 },
  ];
}

let kommunkod2kommun = {};

export function get_kommun_from_code(kommunkod) {
  if (!(kommunkod in kommunkod2kommun)) {
    // first initialization
    for (let i = 0; i < raw_kommuner.length; i++) {
      kommunkod2kommun[raw_kommuner[i]["value"]] = raw_kommuner[i]["label"];
    }
  }

  return kommunkod2kommun[kommunkod];
}
