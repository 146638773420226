import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/SvgLogo.svg";
import logoDarkMode from "../assets/images/SvgLogoDarkMode.svg";
import logoSCB from "../assets/images/SCB.webp";
import logoArbetsförmedlingen from "../assets/images/Arbetsförmedlingen.webp";
import logoMyh from "../assets/images/Myh.webp";
import logoSkolverket from "../assets/images/Skolverket.webp";
import { DarkModeContext } from "../App";
import { Container, Row } from "react-bootstrap";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

const StarterPage = (props) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;

  const darkMode = useContext(DarkModeContext);

  const logoSrc = darkMode ? logoDarkMode : logo;

  return (
    <>
      <section>
        <Container>
          <Row>
            <div className="step-content">
              <h1 className="mb-4">
                Hitta din utbildning med arbetsmarknadsprognos
              </h1>

              <div className="card-style rounded-1 p-4 mb-4">
                <h2 className="mb-3">Välkommen hit!</h2>

                <p>
                  Genom att använda denna tjänst kan du söka efter den
                  utbildning du är intresserad av och få en prognos på
                  arbetsmarknadens efterfrågan av de kunskaper du vill utveckla.
                </p>
                <br />

                <h3 className="mb-3">Hur tjänsten fungerar.</h3>
                <p>
                  I tjänsten har vi samlat de flesta offentligt finansierade
                  utbildningarna (200 000+) på ett och samma ställe. Det betyder
                  att du hittar Komvuxutbildningar, Yrkeshögskoleutbildningar,
                  Universitets- och högskoleutbildningar och
                  Folkhögskoleutbildningar här.
                </p>
                <br />
                <p>
                  Genom att söka och välja den utbildning du är intresserad av
                  kan du sedan generera en prognos på arbetsmarknadens
                  efterfrågan på de kunskaper du vill utveckla. Prognoserna
                  baseras på flera källor som Statistiska Centralbyrån (SCB),
                  Myndigheten för Yrkeshögskolan (MYH), Skolverket och
                  Arbetsförmedlingen.
                </p>
                <br />
                <p>
                  {/* Tjänsten är en beta-version, vilket innebär att tjänsten
                  fortfarande utvecklas. Trots att vi har kartlagt majoriteten
                  av de 200 000+ offentligt finansierade utbildningarna, kan det
                  vara bra att veta att inte alla utbildningar har en prognos
                  än. Vi jobbar ständigt med uppdateringar och vi ber dig att ha
                  tålamod om resultatet verkar svårtolkat eller om det ännu inte
                  finns en prognos. Vi jobbar kontinuerligt med att utveckla
                  tjänsten. */}
                </p>

                <br />

                <h3 className="mb-3">Så kommer du igång:</h3>
                <ul>
                  <li>
                    Klicka på knappen 'Hitta din utbildning' längst ner på denna
                    sida.
                  </li>
                  <li>
                    När du tryckt på knappen kommer du till vår
                    utbildningskatalog där du kan söka efter utbildningar.
                  </li>
                  <li>
                    Välj den utbildning du är intresserad av och klicka på 'välj
                    utbildningen och generera en prognos'.
                  </li>
                  <li>
                    Du får nu en summering av den prognos som finns för den
                    valda utbildningens efterfrågan på arbetsmarknaden.
                  </li>
                  <li>
                    Det finns ingen begränsning för hur många sökningar du kan
                    göra.
                  </li>
                </ul>

                <br />

                <p>Redo att börja? Klicka på knappen ”Hitta din utbildning”.</p>

                <div className="d-flex justify-content-center pt-4">
                  <button
                    type="button"
                    onClick={() => props.goToStep(2)}
                    className={`${isMobile ? "w-100" : ""} btn-outline-tsl`}
                  >
                    Hitta din utbildning
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>

      <div class="d-flex align-items-end justify-content-between pb-4 px-4 gap-4 gap-4 mt-auto">
        <div class="powered-tsl d-flex flex-column">
          <span>Powered by</span>
          <img src={logoSrc} alt="Trygghetsfonden TSL" />
        </div>
        <div class="data-from d-flex flex-column">
          <span>Data from</span>

          <div className="d-flex flex-column flex-md-row align-items-start gap-3 gap-md-4">
            <img src={logoSCB} alt="SCB" />
            <img src={logoArbetsförmedlingen} alt="Arbetsförmedlingen" />
            <img src={logoMyh} alt="Myh" />
            <img src={logoSkolverket} alt="Skolverket" />
          </div>
        </div>
      </div>
    </>
  );
};

export default StarterPage;
