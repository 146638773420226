// AKB
export let _all_mappings = [];
export let _all_mappingsCombined = [];
export let _all_mappingsUtbgruppYrke = [];
export let _all_tab0 = [];
export let _all_tab0Riket = [];
export let _all_AKBAntal = [];
export let _all_AKBTillgang = [];

// Af
export let _all_yrkesgrupper = [];

// Trends
export let _all_skills_trend = [];

// MYH
export let _all_rapporter = [];

export function set_data(all_mappings, all_mappingsCombined, all_mappingsUtbgruppYrke, 
    all_tab0, all_tab0Riket, all_AKBAntal, all_AKBTillgang, 
    all_yrkesgrupper, all_skills_trend, all_rapporter) {
    
    _all_mappings = all_mappings;    
    _all_mappingsCombined = all_mappingsCombined;
    _all_mappingsUtbgruppYrke = all_mappingsUtbgruppYrke; 
    _all_tab0 = all_tab0;
    _all_tab0Riket = all_tab0Riket;
    _all_AKBAntal = all_AKBAntal;
    _all_AKBTillgang = all_AKBTillgang; 
    _all_yrkesgrupper = all_yrkesgrupper;
    _all_skills_trend = all_skills_trend;
    _all_rapporter = all_rapporter;
}