/*
    Trends based on ads data.
*/

import { generate_path } from '../utils/paths.js';

import { _all_skills_trend } from '../utils/data.js';
//import _all_skills_trend from '../data/skills_trend.json'  assert { type: 'json' };

//const fs = import('fs');

//const file_path = 'C:/Users/Simon/Documents/Projects/prognos_utbildning/library/version1/src/data/';
const api_analysis = 'https://api-analysis.inteltjanster.workers.dev';

//let _all_skills_trend = []
let skill2trend = {}
let skills = ['Svenska','Engelska', 'Projektledning', 'Ledarskap', 'Digital', 'Skriva', 'Python', 'Javascript', 'Html', 'buss', 'truckkort','Cnc','Svets']

/*export async function fetch_trends_from_API() {
    
    // skills
    let url = api_analysis + '/skills_trend';
    _all_skills_trend = await (await fetch(url)).json();

    for(let i=0;i<skills.length;i++) {
        let item = _all_skills_trend.filter(x=>x['name'] == skills[i])[0]
        skill2trend[skills[i].toLowerCase()] = item
    }
}*/

export async function initialize_ads() {
    
    // skills
    //let rawdata = (await fs).readFileSync(file_path + 'skills_trend.json')
    //_all_skills_trend = JSON.parse(rawdata);

    for(let i=0;i<skills.length;i++) {
        let item = _all_skills_trend.filter(x=>x['name'] == skills[i])[0]
        skill2trend[skills[i].toLowerCase()] = item
    }
}

// Check titles and returns trends for any pre-determined skills word(s)
export async function get_trends(courses) {

    let res = [], paths = [];

    for(let i=0;i<courses.length;i++) {
        let title = courses[i].info.title.string[0].content.toLowerCase();
        let found_skills = Object.keys(skill2trend).filter(x=>title.includes(x));

        let r = []
        let p = [];
        
        for(let j=0;j<found_skills.length;j++) {
            r.push(skill2trend[found_skills[j]])
            p.push(generate_path(title, title, 'Title of course', 'title', found_skills[j], found_skills[j], 'Skill', 'Skill', skill2trend[found_skills[j]], 'Skill', 1.0 ));
        }
        
        res.push(r);
        paths.push(p);
    }

    return [res, paths]
}