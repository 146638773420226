import React, { useContext } from 'react';
import './GaugeChart.css';
import pointer from '../../assets/images/SvgPointer.svg'
import pointerDarkMode from '../../assets/images/SvgPointerDarkMode.svg'

import { DarkModeContext } from '../../App';

function GaugeChart({value}) {

  const degrees = value * 1.8 - 180;

  const darkMode = useContext(DarkModeContext);

  const pointerSrc = darkMode ? pointerDarkMode : pointer;

  return (
    <div className="gauge">
      <div className="gauge-body">
        <div className="gauge-cover"></div>
        <img src={pointerSrc} alt={value + '%'} className="gauge-pointer" style={{transform: `rotate(${degrees}deg)`}} />
      </div>
    </div>
  );
}

export default GaugeChart;