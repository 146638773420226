export function get_code_from_utb(course) {

    let codes = [];

    // course info
    const info = course.info;

    const form = info['form']['code'];
    
    // yrkeshögskola
    if(form == 'yrkeshögskoleutbildning') {
        const subjects = info['subject'];

        for(let i=0;i<subjects.length;i++) {
            const s = subjects[i];
            const full = s['code'];
            const code = full.substring(3,full.length);
            const niva = full.substring(0,3);
            codes.push({ 'code': code, 'type': 'sun', 'niva': niva })
        }
    }


    // högskolan
    if(form == 'högskoleutbildning') {
        
        // examen
        if(info['resultIsDegree'] == true) {
            for(let i=0;i<info['degree'].length;i++) {
                const code = info['degree'][i]['string'][0]['content'];
                codes.push({ 'code': code, 'type': 'yrkesexamen', 'niva': '' })
            }
        }
        
        // kurs / program
        const subjects = info['subject'];
        let cs = subjects.map(x => x['code'])
        cs = [...new Set(cs)];
        codes.push(...cs.map(x=> { return { 'code': x, 'type': 'uh-kurs', 'niva':'' } }))
    }

    // folkhögskola, komvux, sfi, särvux
    if(['folkhögskola','gymnasial vuxenutbildning', 'sfi', 'särvux', 'grundläggande vuxenutbildning'].includes(form)) {
        const subjects = info['subject'];
        let cs = subjects.map(x => x['code']);
        cs = [...new Set(cs)];

        let t = form == 'folkhögskola'? 'folkhogskolan': 'komvux';

        codes = cs.map(x=> { return { 'code': x, 'type': t, 'niva':'' } });
    }

    return codes;
}